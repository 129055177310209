import styled from 'styled-components';

export const HomeContent = styled.div`
  position: relative;
  z-index: 0;
  width: 100%;
  overflow: hidden;
`;

export const PageHeaderContainer = styled.section`
  width: 50%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  position: relative;
  z-index: 1;
  padding: ${({ theme }) => `${theme.newTheme.spacing(35)} 0`};

  p {
    color: ${({ theme }) => theme.newTheme.color.white};
  }

  b {
    display: block;
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tablet}) {
    width: 60%;
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    width: 100%;
    height: auto;
  }
`;

export const PageHeaderSubtitle = styled.h1`
  font-size: ${({ theme }) => theme.newTheme.font.title1.size};
  line-height: ${({ theme }) => theme.newTheme.font.title1.lineHeight};
  margin: -3px 0 0 0;

  u {
    text-decoration: none;
    display: block;
  }

  b {
    background: ${({ theme }) => theme.newTheme.color.gradient.background.tertiary.main};
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: ${({ theme }) => theme.newTheme.font.weight.bold};
  }

  p {
    font-weight: ${({ theme }) => theme.newTheme.font.weight.bold};
    margin: 0;
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tablet}) {
    width: 100%;
    font-size: ${({ theme }) => theme.newTheme.font.title2.size};
    line-height: ${({ theme }) => theme.newTheme.font.title2.lineHeight};
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    font-size: ${({ theme }) => theme.newTheme.font.title3.size};
    line-height: ${({ theme }) => theme.newTheme.font.title3.lineHeight};
  }

  @media only screen and (max-height: 700px) {
    font-size: ${({ theme }) => theme.newTheme.font.title2.size};
    line-height: ${({ theme }) => theme.newTheme.font.title2.lineHeight};
  }
`;

export const PageHeaderDescription = styled.p`
  font-size: ${({ theme }) => theme.newTheme.font.text1.size};
  line-height: ${({ theme }) => theme.newTheme.font.text1.lineHeight};
  color: ${({ theme }) => theme.newTheme.color.grey.main};
  margin-top: ${({ theme }) => theme.newTheme.spacing(11)};
  padding-bottom: ${({ theme }) => theme.newTheme.spacing(10)};
  width: 90%;

  ::first-line {
    font-weight: ${({ theme }) => theme.newTheme.font.weight.bold};
  }

  b {
    color: ${({ theme }) => theme.newTheme.color.white};
    font-weight: ${({ theme }) => theme.newTheme.font.weight.bold};
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    line-height: ${({ theme }) => theme.lineHeight.secondary};
    width: 100%;
  }
`;

export const Content = styled.div`
  position: relative;
  margin: ${({ theme }) => theme.newTheme.spacing(16)} auto;
  h5 {
    font-weight: ${({ theme }) => theme.newTheme.font.weight.semiBold};
    font-size: ${({ theme }) => theme.newTheme.font.title3.size};
    line-height: ${({ theme }) => theme.newTheme.font.title3.lineHeight};

    i {
      font-style: normal;
      font-weight: ${({ theme }) => theme.newTheme.font.weight.semiBold};
    }

    @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
      font-size: ${({ theme }) => theme.newTheme.font.title6.size};
      line-height: ${({ theme }) => theme.newTheme.font.title6.lineHeight};
      position: relative;
      z-index: 9;

      i {
        font-size: ${({ theme }) => theme.newTheme.font.title6.size};
        line-height: ${({ theme }) => theme.newTheme.font.title6.lineHeight};
        display: block;
      }

      b {
        display: block;
      }
    }
  }
  svg {
    opacity: 0.1;
    transform: translateX(15px);

    @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
      position: absolute;
      width: ${({ theme }) => theme.newTheme.spacing(20)};
      z-index: 0;
      transform: translateX(-90px) translateY(-40px);
    }
  }

  blockquote p {
    padding: 0;
  }

  q {
    font-size: ${({ theme }) => theme.newTheme.font.text1.size};
    line-height: ${({ theme }) => theme.newTheme.font.text1.lineHeight};

    @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
      font-size: ${({ theme }) => theme.newTheme.font.text2.size};
      line-height: ${({ theme }) => theme.newTheme.font.text2.lineHeight};
    }
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    margin: ${({ theme }) => theme.newTheme.spacing(26)} auto;
  }
`;

export const MainContent = styled.div`
  position: relative;
`;

export const Diagonal = styled.div`
  position: absolute;
  z-index: 0;
  left: 0;
  border-top: ${({ theme }) =>
    `${theme.newTheme.spacing(20)} solid ${theme.newTheme.color.primary.light}20`};
  border-left: 100vw solid transparent;

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    border-top: ${({ theme }) =>
      `${theme.newTheme.spacing(10)} solid ${theme.newTheme.color.primary.light}20`};
  }
`;

export const ClientTitle = styled.h4`
  font-size: ${({ theme }) => theme.newTheme.font.title3.size};
  line-height: ${({ theme }) => theme.newTheme.font.title3.lineHeight};
  font-weight: ${({ theme }) => theme.newTheme.font.weight.semiBold};
  color: ${({ theme }) => theme.colors.black};
  margin-top: 0;
  margin-bottom: ${({ theme }) => theme.newTheme.spacing(9)};
  width: 100%;

  span {
    &:nth-child(1) {
      font-weight: ${({ theme }) => theme.fonts.weights.heavy};
      margin-right: ${({ theme }) => theme.newTheme.spacing(3)};
      color: ${({ theme }) => theme.colors.black};
    }

    &:nth-child(2) {
      background: ${({ theme }) => theme.gradients.background};
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: ${({ theme }) => theme.fonts.weights.heavy};
      display: block;
      margin-bottom: ${({ theme }) => theme.newTheme.spacing(12)};

      @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
        margin-bottom: ${({ theme }) => theme.newTheme.spacing(7)};
      }
    }
  }

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    font-size: ${({ theme }) => theme.newTheme.font.title4.size};
    line-height: ${({ theme }) => theme.newTheme.font.title4.lineHeight};
    margin-top: 0;

    span {
      display: block;
    }
  }
`;

export const ClientsContainer = styled.div`
  div {
    padding: 0;
  }
`;

export const WeSpeakContent = styled.div`
  display: flex;

  h5 {
    color: ${({ theme }) => theme.newTheme.color.black};
    font-size: ${({ theme }) => theme.newTheme.font.title3.size};
    line-height: ${({ theme }) => theme.newTheme.font.title3.lineHeight};
    font-weight: ${({ theme }) => theme.newTheme.font.weight.semiBold};

    span {
      display: block;
      font-size: ${({ theme }) => theme.newTheme.font.title3.size};
      line-height: ${({ theme }) => theme.newTheme.font.title3.lineHeight};
      font-weight: ${({ theme }) => theme.newTheme.font.weight.semiBold};
      background: ${({ theme }) => theme.newTheme.color.gradient.text};
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
      font-size: ${({ theme }) => theme.newTheme.font.title4.size};
      line-height: ${({ theme }) => theme.newTheme.font.title4.lineHeight};
      span {
        font-size: ${({ theme }) => theme.newTheme.font.title4.size};
        line-height: ${({ theme }) => theme.newTheme.font.title4.lineHeight};
      }
    }
  }

  p {
    width: 90%;
  }

  @media only screen and (${({ theme }) => theme.breakpoints.tablet}) {
    img {
      order: 2 !important;
    }
    div:nth-child(1) {
      display: flex;
      flex-direction: column-reverse;
    }
  }
`;

export const ContentText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tabletLandscape}) {
    height: 100%;

    div {
      height: 100%;
    }
  }
`;

export const ContainerWords = styled(ContentText)`
  flex-direction: row;
  overflow: hidden;

  svg:nth-child(1) {
    transform: translateX(30px);
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tabletLandscape}) {
    margin: ${({ theme }) => `${theme.newTheme.spacing(25)} 0`};

    svg {
      height: ${({ theme }) => `${theme.newTheme.spacing(60)}`};
    }
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tablet}) {
    svg {
      height: ${({ theme }) => `${theme.newTheme.spacing(55)}`};
    }
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    margin: ${({ theme }) => `${theme.newTheme.spacing(20)} 0`};
    transform: translateX(-20px);

    svg {
      height: ${({ theme }) => `${theme.newTheme.spacing(50)}`};
    }

    svg:nth-child(1) {
      transform: translateX(35px);
    }

    svg:nth-child(3) {
      transform: translateX(-10px);
    }
  }
`;

export const WordList = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0;
  padding: 0;
`;

export const Word = styled.li`
  color: ${({ theme }) => theme.newTheme.color.white};
  font-size: ${({ theme }) => theme.newTheme.font.title1.size};
  line-height: ${({ theme }) => theme.newTheme.font.title1.lineHeight};
  font-weight: ${({ theme }) => theme.newTheme.font.weight.semiBold};
  margin: ${({ theme }) => `${theme.newTheme.spacing(2)} 0`};

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tabletLandscape}) {
    font-size: ${({ theme }) => theme.newTheme.font.title2.size};
    line-height: ${({ theme }) => theme.newTheme.font.title2.lineHeight};
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tablet}) {
    font-size: ${({ theme }) => theme.newTheme.font.title3.size};
    line-height: ${({ theme }) => theme.newTheme.font.title3.lineHeight};
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    font-size: ${({ theme }) => theme.newTheme.font.title4.size};
    line-height: ${({ theme }) => theme.newTheme.font.title4.lineHeight};
  }
`;

export const ContainerGrid = styled.div`
  max-width: 100% !important;
`;

export const RowGrid = styled.div`
  padding-left: ${({ theme }) => theme.newTheme.spacing(6)} !important;
`;

export const ColGrid = styled.div`
  padding-left: 0 !important;
  padding-right: 0 !important;

  div {
    height: 90vh !important;
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tabletLandscape}) {
    div {
      height: 60vh !important;
    }
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tablet}) {
    div {
      height: 50vh !important;
    }
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    height: 50vh;
  }
`;

export const ImgContainer = styled.div`
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  div {
    width: 100%;
    height: 100%;
  }

  img {
    background-position: center center;
    background-attachment: fixed;
    background-size: cover;
    object-fit: cover;
    height: 100%;
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tabletLandscape}) {
    height: 100vh;

    div {
      height: 100vh;
    }
  }
`;

export const MoreThanDevelopmentContent = styled.div`
  position: relative;
  z-index: 0;
  width: 100%;
  overflow: hidden;

  section {
    height: 100vh;

    @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
      height: auto;
    }
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    height: auto;
  }
`;

export const TextContainer = styled.div`
  width: 60%;
  height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  position: relative;
  z-index: 1;
  padding: ${({ theme }) => `${theme.newTheme.spacing(35)} 0`};

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tablet}) {
    width: 60%;
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    width: 100%;
    padding: ${({ theme }) => `${theme.newTheme.spacing(20)} ${theme.newTheme.spacing(7)} 0`};
    height: auto;
  }
`;

export const Title = styled.h3`
  font-size: ${({ theme }) => theme.newTheme.font.title2.size};
  line-height: ${({ theme }) => theme.newTheme.font.title2.lineHeight};
  color: ${({ theme }) => theme.newTheme.color.white};
  margin: -3px 0 0 0;

  span {
    color: ${({ theme }) => theme.newTheme.color.white};
    font-weight: ${({ theme }) => theme.newTheme.font.weight.bold};
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tablet}) {
    width: 100%;
    font-size: ${({ theme }) => theme.newTheme.font.title3.size};
    line-height: ${({ theme }) => theme.newTheme.font.title3.lineHeight};
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    width: 100%;
    font-size: ${({ theme }) => theme.newTheme.font.title4.size};
    line-height: ${({ theme }) => theme.newTheme.font.title4.lineHeight};
  }

  @media only screen and (max-height: 688px) {
    font-size: ${({ theme }) => theme.newTheme.font.title3.size};
    line-height: ${({ theme }) => theme.newTheme.font.title3.lineHeight};
  }
`;

export const Description = styled.p`
  font-size: ${({ theme }) => theme.newTheme.font.text1.size};
  line-height: ${({ theme }) => theme.newTheme.font.text1.lineHeight};
  color: ${({ theme }) => theme.newTheme.color.white};
  margin-top: ${({ theme }) => theme.newTheme.spacing(11)};
  padding-bottom: ${({ theme }) => theme.newTheme.spacing(10)};
  width: 65%;
  white-space: pre-line;

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    line-height: ${({ theme }) => theme.lineHeight.secondary};
    width: 100%;
  }
`;

export const TestimonialGridItem = styled.div`
  margin: 0;
  padding: 0;
  margin-bottom: ${({ theme }) => theme.newTheme.spacing(20)};

  svg {
    display: none;
  }

  div:nth-child(2) {
    div:nth-child(1) {
      width: 100px !important;
      height: 100px !important;

      @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
        width: 70px !important;
        height: 70px !important;
      }

      p {
        @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
          margin-bottom: 0 !important;
        }
      }
    }

    figcaption {
      width: 60% !important;
      font-size: ${({ theme }) => theme.newTheme.font.text3.size};
      line-height: ${({ theme }) => theme.newTheme.font.text3.weight};

      span {
        color: ${({ theme }) => theme.newTheme.color.primary.main};
        font-size: ${({ theme }) => theme.newTheme.font.text1.size};
        line-height: ${({ theme }) => theme.newTheme.font.text1.weight};

        @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tabletLandscape}) {
          font-size: ${({ theme }) => theme.newTheme.font.text2.size};
          line-height: ${({ theme }) => theme.newTheme.font.text2.weight};
        }
      }

      @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tabletLandscape}) {
        width: 50% !important;
        font-size: ${({ theme }) => theme.newTheme.font.text4.size};
        line-height: ${({ theme }) => theme.newTheme.font.text4.weight};
      }

      @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tablet}) {
        width: 60% !important;
      }

      @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
        width: 65% !important;
      }
    }
    @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
      transform: translateY(-15px);
    }
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tablet}) {
    margin-bottom: ${({ theme }) => theme.newTheme.spacing(20)};
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    margin-bottom: ${({ theme }) => theme.newTheme.spacing(10)};
  }
`;

export const TestimonialGridList = styled.div`
  margin: 0;
  padding: 0;
  list-style: none;

  p {
    height: ${({ theme }) => theme.newTheme.spacing(30)} !important;

    @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tablet}) {
      height: ${({ theme }) => theme.newTheme.spacing(20)} !important;
    }

    @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
      height: auto !important;
    }

    q {
      font-size: ${({ theme }) => theme.newTheme.font.text2.size};
      line-height: ${({ theme }) => theme.newTheme.font.text2.weight};

      @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tabletLandscape}) {
        font-size: ${({ theme }) => theme.newTheme.font.text3.size};
        line-height: ${({ theme }) => theme.newTheme.font.text3.weight};
      }
    }
  }

  button {
    padding: 0;
    margin-top: ${({ theme }) => theme.newTheme.spacing(4)};
    color: ${({ theme }) => theme.newTheme.color.black};
    text-decoration: underline;
    font-size: ${({ theme }) => theme.newTheme.font.text3.size};
  }
`;

export const TestimonialGridTitle = styled.h4`
  font-size: ${({ theme }) => theme.newTheme.font.title3.size};
  line-height: ${({ theme }) => theme.newTheme.font.title3.lineHeight};
  font-weight: ${({ theme }) => theme.newTheme.font.weight.semiBold};
  color: ${({ theme }) => theme.colors.black};
  margin-top: 0;
  margin-bottom: ${({ theme }) => theme.newTheme.spacing(12)};
  transform: translateX(-17px);

  span {
    background: ${({ theme }) => theme.gradients.background};
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: ${({ theme }) => theme.newTheme.font.weight.semiBold};
    margin-left: ${({ theme }) => theme.newTheme.spacing(3)};

    @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
      font-size: ${({ theme }) => theme.newTheme.font.title4.size};
      line-height: ${({ theme }) => theme.newTheme.font.title4.lineHeight};
      display: block;
      margin-left: 0;
    }
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    font-size: ${({ theme }) => theme.newTheme.font.title4.size};
    line-height: ${({ theme }) => theme.newTheme.font.title4.lineHeight};
    display: block;
    margin-left: 0;
  }
`;
export const ImgContainerAvatars = styled.div`
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  div {
    width: 100%;
    height: 100%;
  }
  img {
    background-size: cover;
    height: 100%;
  }
`;

export const AvatarsContent = styled.div`
  position: relative;
  z-index: 0;
  width: 100%;
  overflow: hidden;
  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    height: auto !important;
    display: block;
  }
`;

export const AvatarsGeneralContent = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    height: auto !important;
    display: block;
  }
`;

export const AvatarsListContainer = styled.div`
  div:nth-child(1) {
    align-self: center;
    @media only screen and (${({ theme }) => theme.breakpoints.tabletLandscape}) {
      div:nth-child(1) {
        padding: 8% 0;
      }
    }
  }
`;

export const InfoTitle = styled.h3`
  font-size: ${({ theme }) => theme.newTheme.font.title4.size};
  line-height: ${({ theme }) => theme.newTheme.font.title4.lineHeight};
  font-weight: ${({ theme }) => theme.newTheme.font.weight.regular};
  color: ${({ theme }) => theme.newTheme.color.white};
  margin: 0;
  padding: 0;
  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tabletLandscape}) {
    font-size: ${({ theme }) => theme.newTheme.font.title5.size};
    line-height: ${({ theme }) => theme.newTheme.font.title5.lineHeight};
  }
  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tablet}) {
    font-size: ${({ theme }) => theme.newTheme.font.title6.size};
    line-height: ${({ theme }) => theme.newTheme.font.title6.lineHeight};
  }
  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    font-size: ${({ theme }) => theme.newTheme.font.title4.size};
    line-height: ${({ theme }) => theme.newTheme.font.title4.lineHeight};
    font-weight: ${({ theme }) => theme.newTheme.font.weight.medium};
  }
  span {
    display: block;
    color: ${({ theme }) => theme.newTheme.color.white};
    @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
      margin-right: ${({ theme }) => theme.newTheme.spacing(2)};
      font-size: ${({ theme }) => theme.newTheme.font.title4.size};
      line-height: ${({ theme }) => theme.newTheme.font.title4.lineHeight};
      font-weight: ${({ theme }) => theme.newTheme.font.weight.medium};
    }
  }
`;

export const VerticalCentered = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  ${({ theme }) => `margin: ${theme.newTheme.spacing(45)} ${theme.newTheme.spacing(5)}`};

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    margin: 0;
    padding: 0;
  }
`;

export const InfoGeneral = styled.div``;

export const ActionContent = styled.div`
  section {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 !important;
    h4 {
      font-size: ${({ theme }) => theme.newTheme.font.title5.size};
      line-height: ${({ theme }) => theme.newTheme.font.title5.lineHeight};
      color: ${({ theme }) => theme.newTheme.color.white};
      text-align: start;
      width: 70%;
      @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tabletLandscape}) {
        font-size: ${({ theme }) => theme.newTheme.font.title6.size};
        line-height: ${({ theme }) => theme.newTheme.font.title6.lineHeight};
      }
      @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tablet}) {
        font-size: ${({ theme }) => theme.newTheme.font.text1.size};
        line-height: ${({ theme }) => theme.newTheme.font.text1.lineHeight};
      }
      @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
        width: 100%;
      }
    }
    button {
      @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tabletLandscape}) {
        font-size: ${({ theme }) => theme.newTheme.font.text3.size};
        line-height: ${({ theme }) => theme.newTheme.font.text3.lineHeight};
      }
      @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tablet}) {
        font-size: ${({ theme }) => theme.newTheme.font.text4.size};
        line-height: ${({ theme }) => theme.newTheme.font.text4.lineHeight};
      }
    }
    @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
      align-items: center;
      justify-content: center;
    }
  }
`;

export const ColAvatars = styled.div`
  padding: 0 !important;
`;

export const ColAvatarsDesk = styled.div`
  padding-left: 0 !important;
  padding-right: 0 !important;
`;
