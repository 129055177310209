import React from 'react';

import {
  AvatarsBackendDev,
  AvatarsBusinessAnalyst,
  AvatarsDevops,
  AvatarsFrontendDev,
  AvatarsProjectManager,
  AvatarsQAAutomation,
  AvatarsSolutionArchitect,
  AvatarsUXUIDesigner,
} from '../components/ui/svg/avatars';

const avatarsList = [
  {
    id: 0,
    image: <AvatarsBackendDev />,
    name: ['Back-end', <span>Developer</span>],
  },
  {
    id: 1,
    image: <AvatarsQAAutomation />,
    name: 'QA Automation',
  },
  {
    id: 2,
    image: <AvatarsProjectManager />,
    name: ['Project', <span>Manager</span>],
  },
  {
    id: 3,
    image: <AvatarsUXUIDesigner />,
    name: ['UX/UI', <span>Designer</span>],
  },
  {
    id: 4,
    image: <AvatarsDevops />,
    name: 'Devops',
  },
  {
    id: 5,
    image: <AvatarsSolutionArchitect />,
    name: ['Solution', <span>Architect</span>],
  },
  {
    id: 6,
    image: <AvatarsBusinessAnalyst />,
    name: ['Business', <span>Analyst</span>],
  },
  {
    id: 7,
    image: <AvatarsFrontendDev />,
    name: ['Front-end', <span>Developer</span>],
  },
];

export default avatarsList;
