import styled from 'styled-components';

export const Icon = styled.img`
  width: ${({ theme }) => theme.newTheme.spacing(23)};
  height: ${({ theme }) => theme.newTheme.spacing(23)};

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tablet}) {
    width: ${({ theme }) => theme.newTheme.spacing(20)};
    height: ${({ theme }) => theme.newTheme.spacing(20)};
  }
`;

export const TitleCheckList = styled.h4`
  font-size: ${({ theme }) => theme.newTheme.font.title6.size};
  line-height: ${({ theme }) => theme.newTheme.font.title6.lineHeight};
  font-weight: ${({ theme }) => theme.newTheme.font.weight.bold};
  color: ${({ theme }) => theme.newTheme.color.primary.main};
  margin: 0;
  width: 100%;
`;

export const TextCheckList = styled.p`
  font-size: ${({ theme }) => theme.newTheme.font.text1.size};
  line-height: ${({ theme }) => theme.newTheme.font.text1.lineHeight};
  font-weight: ${({ theme }) => theme.newTheme.font.weight.regular};
  color: ${({ theme }) => theme.newTheme.color.grey.main};
  width: 90%;
  margin: 0;
`;

export const Subtitle = styled.h5`
  font-size: ${({ theme }) => theme.newTheme.font.text1.size};
  line-height: ${({ theme }) => theme.newTheme.font.text1.lineHeight};
  font-weight: ${({ theme }) => theme.newTheme.font.weight.regular};
  color: ${({ theme }) => theme.newTheme.color.grey.main};

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tablet}) {
    font-size: ${({ theme }) => theme.newTheme.font.text2.size};
    line-height: ${({ theme }) => theme.newTheme.font.text2.lineHeight};
  }
`;

export const Title = styled.h4`
  font-size: ${({ theme }) => theme.newTheme.font.title3.size};
  line-height: ${({ theme }) => theme.newTheme.font.title3.lineHeight};
  font-weight: ${({ theme }) => theme.newTheme.font.weight.semiBold};
  color: ${({ theme }) => theme.newTheme.color.black};
  margin-top: 0;
  width: 100%;

  span {
    background: ${({ theme }) => `${theme.gradients.background}`};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    b {
      line-height: ${({ theme }) => theme.newTheme.font.title3.lineHeight};
      font-weight: ${({ theme }) => theme.newTheme.font.weight.semiBold};
    }
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tablet}) {
    font-size: ${({ theme }) => theme.newTheme.font.title4.size};
    line-height: ${({ theme }) => theme.newTheme.font.title4.lineHeight};
    margin-bottom: ${({ theme }) => theme.newTheme.spacing(5)};

    b {
      font-size: ${({ theme }) => theme.newTheme.font.title4.size};
      line-height: ${({ theme }) => theme.newTheme.font.title4.lineHeight};
    }
  }
`;

export const ContainerCheckListItem = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;

  padding-bottom: ${({ theme }) => theme.newTheme.spacing(12)};

  ul {
    margin-bottom: 0;
  }

  button {
    border: 1px solid ${({ theme }) => theme.newTheme.color.secondary.main} !important;
  }
`;

export const ContainerInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => `${theme.newTheme.spacing(7)}`};
  img:nth-child(odd) {
    transform: translateX(-10px);
  }
`;

export const ContainerCheckListButton = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => `${theme.newTheme.spacing(5)}`};
  margin-top: ${({ theme }) => `${theme.newTheme.spacing(4)}`};

  div:nth-child(1) {
    gap: 0;
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tablet}) {
    gap: 5;
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    gap: 0;
  }

  button {
    border: 1px solid ${({ theme }) => theme.newTheme.color.secondary.main} !important;
  }
`;


