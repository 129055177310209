import React from 'react';

import checklistHome from 'domain/checklistHome';
import { Container, Row } from 'react-grid-system';
import IconTitleCheckList from 'atomic-design/organisms/icon-title-check-list';
import { ContainerGrid, Subtitle, Title } from './styles';

const ServicesChecklist = () => {
  return (
    <>
      <Subtitle>Ways to Engage</Subtitle>
      <Title>
        How can we
        <span>
          <b> collaborate?</b>
        </span>
      </Title>
      <Container fluid={true} component={ContainerGrid}>
        <Row>
          {checklistHome.map(checkListInfo => (
            <IconTitleCheckList
              key={checkListInfo.key}
              image={checkListInfo.image}
              alt={checkListInfo.alt}
              title={checkListInfo.title}
              description={checkListInfo.description}
              list={checkListInfo.list}
              isPositive={checkListInfo.isPositive}
              to={checkListInfo.to}
              buttonText="Find out more"
              buttonVariant="outlined"
              buttonColor="secondary"
            ></IconTitleCheckList>
          ))}
        </Row>
      </Container>
    </>
  );
};

ServicesChecklist.propTypes = {};

ServicesChecklist.defaultProps = {};

export default ServicesChecklist;
