import React from 'react';
import Link from 'atomic-design/atoms/link';

import theme from 'styles/theme';
import home1 from 'static/images/icons/1-home.svg';
import home2 from 'static/images/icons/2-home.svg';
import home3 from 'static/images/icons/3-home.svg';
import home4 from 'static/images/icons/4-home.svg';

const homeList = {
  title: (
    <>
      From zero to <span>superhero!</span>
    </>
  ),

  description: (
    <>
      <span>You're 4 steps away from leveling up your team.</span>
      From first contact to ramp up, here's what to expect:
    </>
  ),
  buttonText: "Let's get started",
  color: theme.colors.purpleLight,
  list: [
    {
      image: home1,
      alt: 'number 1',
      title: 'Meet',
      description:
        "Our story begins with a conversation to understand your vision, needs, and challenges. We're not just finding a fit; we're crafting a partnership. ",
      link: { text: 'Go ahead, and schedule a meeting here.', url: '/contact' },
    },
    {
      image: home2,
      alt: 'number 2',
      title: 'Assess',
      description:
        'We dive beneath the surface, analyzing your technical architecture and dissecting your code. Time for us to understand your project technicalities and scope it out.',
    },
    {
      image: home3,
      alt: 'number 3',
      title: 'Strategize & Plan',
      description:
        'We prepare a detailed project plan, revealing our technical insights. Your dream team, meticulously selected, awaits their call to action.',
    },
    {
      image: home4,
      alt: 'number 4',
      title: 'Ignite',
      description:
        "The grand kick-off awaits! Meetings are set, and your squad awaits. May the journey begin.The rest? Well, that's where history gets made. ",
      link: { text: 'See for yourself!', url: '/blog/category/client-stories/' },
      isLast: true,
    },
  ],
};

export default homeList;
