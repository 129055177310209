import React from 'react';

export const AvatarsHeroDesk = () => (
  <svg fill="none" viewBox="0 0 1691 780" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#a)">
      <rect
        transform="matrix(.71716 .69691 -.69837 .71573 494.69 -621)"
        width="891.58"
        height="1023.4"
        rx="32.562"
        fill="#8294FF"
        fillOpacity=".45"
      />
    </g>
    <defs>
      <clipPath id="a">
        <rect width="1691" height="780" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);

export const AvatarsHeroMob = () => (
  <svg
    width="375"
    height="1233"
    viewBox="0 0 375 1233"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_2473_7684"
      style="mask-type:alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="375"
      height="1233"
    >
      <rect width="375" height="1233" fill="#C4C4C4" />
    </mask>
    <g mask="url(#mask0_2473_7684)">
      <rect
        width="743.752"
        height="853.687"
        rx="28"
        transform="matrix(0.71716 0.696909 -0.698374 0.715733 134.648 -10.2422)"
        fill="#8294FF"
        fillOpacity="0.6"
      />
    </g>
  </svg>
);

export const AvatarsParticlesDesk = () => (
  <svg fill="none" viewBox="0 0 1698 780" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="a"
      x="0"
      y="0"
      width="1698"
      height="780"
      style="mask-type:alpha"
      maskUnits="userSpaceOnUse"
    >
      <rect width="1698" height="780" fill="#C4C4C4" />
    </mask>
    <g mask="url(#a)">
      <rect
        transform="matrix(.39776 -.91749 .91886 .39459 956 734.28)"
        width="18.841"
        height="28.264"
        rx="9.4203"
        fill="#f9f"
      />
      <rect
        transform="matrix(.39776 -.91749 .91886 .39459 230 69.621)"
        width="13.757"
        height="23.912"
        rx="6.8784"
        fill="#8294FF"
      />
      <rect
        transform="matrix(.39776 -.91749 .91886 .39459 1267 91.285)"
        width="18.841"
        height="23.338"
        rx="9.4203"
        fill="#8294FF"
      />
      <rect
        transform="matrix(.39776 -.91749 .91886 .39459 74.002 630.28)"
        width="18.841"
        height="29.284"
        rx="9.4203"
        fill="#C9CEFA"
      />
      <rect
        transform="matrix(.39776 -.91749 .91886 .39459 383.05 606.34)"
        width="12.136"
        height="21.095"
        rx="6.0681"
        fill="#f9f"
      />
      <rect
        transform="matrix(.39769 -.91752 .91883 .39466 120.99 216.59)"
        width="17.043"
        height="25.564"
        rx="8.5214"
        fill="#6271EE"
        fillOpacity=".3"
      />
      <rect
        transform="matrix(.39776 -.91749 .91886 .39459 1526 585.29)"
        width="18.841"
        height="47.984"
        rx="9.4203"
        fill="#f9f"
      />
      <rect
        transform="matrix(.39776 -.91749 .91886 .39459 41.002 216.28)"
        width="18.841"
        height="47.984"
        rx="9.4203"
        fill="#f9f"
      />
      <rect
        transform="matrix(.39776 -.91749 .91886 .39459 680 72.285)"
        width="18.841"
        height="31.418"
        rx="9.4203"
        fill="#039BE5"
      />
      <rect
        transform="matrix(.39776 -.91749 .91886 .39459 470 663.28)"
        width="18.841"
        height="31.418"
        rx="9.4203"
        fill="#039BE5"
      />
      <rect
        transform="matrix(.39776 -.91749 .91886 .39459 387.8 278.29)"
        width="18.841"
        height="31.418"
        rx="9.4203"
        fill="#039BE5"
      />
    </g>
  </svg>
);

export const AvatarsParticlesMob = () => (
  <svg
    width="375"
    height="1234"
    viewBox="0 0 375 1234"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="18.8407"
      height="28.2645"
      rx="9.42033"
      transform="matrix(0.397755 -0.917491 0.918857 0.394592 171 1029.29)"
      fill="#FF99FF"
    />
    <rect
      width="18.8407"
      height="28.2645"
      rx="9.42033"
      transform="matrix(0.397755 -0.917491 0.918857 0.394592 171 1222.29)"
      fill="#6271EE"
    />
    <rect
      width="18.8407"
      height="31.4184"
      rx="9.42033"
      transform="matrix(0.397755 -0.917491 0.918857 0.394592 170 67.2852)"
      fill="#039BE5"
    />
    <mask
      id="mask0_2473_7685"
      style="mask-type:alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="375"
      height="1233"
    >
      <rect width="375" height="1233" fill="#C4C4C4" />
    </mask>
    <g mask="url(#mask0_2473_7685)">
      <rect
        width="18.8407"
        height="47.9838"
        rx="9.42033"
        transform="matrix(0.397755 -0.917491 0.918857 0.394592 343 228.285)"
        fill="#FF99FF"
      />
      <rect
        width="6.41603"
        height="10.6993"
        rx="3.20801"
        transform="matrix(0.397755 -0.917491 0.918857 0.394592 19 334.887)"
        fill="#039BE5"
      />
    </g>
  </svg>
);

export const AvatarsBackendDev = () => (
  <svg width="65" height="102" viewBox="0 0 65 102" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.4859 51.7947C9.51214 51.7947 7.55584 51.2134 4.82755 52.7577C2.09925 54.302 1.70448 59.9325 3.62569 61.8586C5.5469 63.7846 11.2404 63.7846 11.2404 63.7846"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M54.352 51.7947C55.3258 51.7947 57.2821 51.2134 60.0103 52.7577C62.7386 54.302 63.1334 59.9325 61.2122 61.8586C59.291 63.7846 53.5975 63.7846 53.5975 63.7846"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M10.9297 48.5674C10.9297 48.5674 11.3512 70.6768 12.6062 72.5823C14.7042 75.7551 25.4469 82.5475 36.0211 81.189C46.5953 79.8306 53.5916 70.8592 53.5916 70.0477C53.5916 69.2363 53.1514 45.1211 53.1514 45.1211"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M33.4004 47.0781L33.5404 57.5447"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M38.9902 43.5408C38.9902 43.5408 42.3339 42.4558 44.2914 42.8661"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M25.0687 43.7572C25.0687 43.7572 21.7251 42.6722 19.7676 43.0734"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M13.0684 32.9906C13.0684 32.9906 18.8378 23.9463 25.7499 27.3653C27.84 28.325 29.8155 29.5052 31.6411 30.8846C31.8962 31.1033 32.2291 31.2163 32.5686 31.1992C32.9082 31.1822 33.2274 31.0365 33.4581 30.7934C35.3313 28.8696 39.2275 25.1954 42.131 25.7424C46.0459 26.4627 48.4623 31.2128 48.4623 31.2128"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M10.2242 51.4918C9.47084 51.3045 -0.389122 52.4283 1.88992 31.5719C4.16896 10.7156 31.8322 5.8831 46.05 9.61047C60.2678 13.3378 63.3001 33.0236 63.3001 35.112C63.3001 37.2005 63.4908 49.2535 60.4394 49.8154C58.1728 50.2867 55.9423 50.9125 53.7644 51.6885"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M6.36988 32.4375C6.36988 32.4375 3.01686 39.1387 12.7013 43.1321"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M10.0888 31.8945C9.75066 33.1198 9.80041 34.416 10.2316 35.6133C10.6627 36.8106 11.4552 37.8533 12.5052 38.6048"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M8.41459 16.8615C8.41459 16.8615 1.85842 2.17357 15.1113 1.45331C28.3641 0.733044 30.0031 8.5192 30.0031 8.5192"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M19.1934 51.0852C19.1934 51.0852 21.4225 48.35 25.89 51.2676"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M40.3652 51.0852C40.3652 51.0852 42.5943 48.35 47.0713 51.2676"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M42.1719 79.4766L44.3541 79.5769C44.6589 79.5887 44.9466 79.7168 45.1552 79.9335C45.3637 80.1501 45.4763 80.4379 45.4687 80.7347C45.4031 83.0049 45.5624 88.1562 48.035 90.0799C49.1557 91.035 50.4189 91.8189 51.7814 92.4048C51.989 92.4834 52.1695 92.6176 52.3021 92.7918C52.4347 92.966 52.5139 93.1733 52.5306 93.3895L52.8959 98.9146"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M23.1583 79.5586L20.7607 79.8777C20.515 79.9074 20.2914 80.0304 20.1388 80.2201C19.9861 80.4097 19.9167 80.6505 19.9458 80.8897C20.1893 83.0232 20.5359 88.4388 18.1569 90.6452C17.0847 91.739 15.8426 92.662 14.4761 93.3804C14.3071 93.4522 14.1636 93.5709 14.0636 93.7218C13.9637 93.8726 13.9117 94.0488 13.9141 94.2283L13.9891 99.9084"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M21.6026 79.7617C20.8252 79.7617 10.4477 79.7617 7.76907 84.5209C5.09041 89.2802 6.35481 100.212 6.35481 100.212"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M44.4863 79.5586C45.2543 79.5586 55.379 79.5586 57.9827 84.254C60.5864 88.9494 59.3688 99.7533 59.3688 99.7533"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M7.66314 61.7972C7.66314 61.7972 7.85046 65.7814 8.03778 66.3558C8.2251 66.9302 7.47582 67.4408 5.98663 67.0852C4.49744 66.7296 5.24672 65.6355 5.24672 65.0885C5.24672 64.5415 4.31012 58.0226 5.43404 57.1109C6.55795 56.1992 8.77769 56.0259 9.33965 57.4756C9.63752 58.5551 9.57186 59.6991 9.15233 60.7396H8.21573L7.66314 61.7972Z"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.875 66.6992C23.875 66.6992 26.1135 71.2579 33.5594 71.2579C41.0054 71.2579 43.6091 69.2612 43.6091 69.2612"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M28.5586 70.5039C28.5586 70.5039 29.4952 73.7679 32.8482 73.9502C36.2012 74.1326 38.0557 71.0509 38.0557 71.0509"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M6.40331 67.5658C5.64708 67.7433 4.64928 80.6109 17.7982 85.5463C30.9471 90.4816 51.5071 87.1263 55.7987 79.1108C58.0397 75.3198 59.101 71.0137 58.8614 66.6836"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M34.4654 10.6953C34.4654 10.6953 37.7716 15.7372 37.8184 19.3841C37.8652 23.031 33.4258 30.7807 33.4258 30.7807"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M41.6719 11.8047C41.6719 12.0144 45.8585 15.3422 45.5775 19.7641C45.2965 24.1859 42.5054 25.5353 42.5054 25.5353"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M15.6641 18.166C15.7781 16.2909 16.6164 14.4991 18.0504 13.065C19.4844 11.631 22.7585 10.1927 24.9271 9.78516"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M25.9961 80.3789C26.1366 80.7801 30.3232 85.2658 32.1402 85.5393C33.9572 85.8128 38.6963 80.6433 38.6963 80.6433"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M57.2197 60.6022C57.2197 60.6022 57.0324 64.5865 56.8544 65.1608C56.6765 65.7352 57.407 66.2458 58.8962 65.8811C60.3854 65.5164 59.6455 64.4315 59.6455 63.8935C59.6455 63.3556 60.5821 56.8185 59.4582 55.9159C58.3343 55.0133 56.1052 54.831 55.5432 56.2806C55.2506 57.3606 55.3162 58.503 55.7305 59.5446H56.6671L57.2197 60.6022Z"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.1228 59.4492C27.1228 59.4492 26.5702 61.7559 28.6588 61.7559H31.0378"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M39.6536 59.5C39.6536 59.5 40.2156 61.8158 38.1176 61.8158H35.748"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M31.2266 61.5742C31.2266 61.5742 31.2266 63.5983 32.9874 63.7533C34.7482 63.9082 35.3944 63.425 35.5255 61.6289"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M37.5195 62.832C37.4695 63.845 37.0208 64.8004 36.2667 65.5001C35.5125 66.1998 34.5106 66.5901 33.4687 66.5901C32.4269 66.5901 31.425 66.1998 30.6708 65.5001C29.9166 64.8004 29.468 63.845 29.418 62.832"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M55.5056 30.9766C55.5056 30.9766 58.8586 37.6869 49.1836 41.6711"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M51.793 30.4453C52.1281 31.6702 52.0759 32.965 51.6431 34.1606C51.2103 35.3561 50.4171 36.3967 49.3672 37.1465"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M13.9104 94.2163L13.7891 89.2109"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M52.5784 94.1499L52.3535 89.1445"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);

export const AvatarsQAAutomation = () => (
  <svg width="70" height="98" viewBox="0 0 70 98" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      width="16.6211"
      height="24.9348"
      rx="8.31057"
      transform="matrix(0.397755 -0.917491 0.918857 0.394592 39.959 87.6484)"
      fill="#FF99FF"
    />
    <rect
      width="16.6211"
      height="24.9348"
      rx="8.31057"
      transform="matrix(0.397755 -0.917491 0.918857 0.394592 39.959 87.6484)"
      fill="#FF99FF"
    />
    <path
      d="M13.2988 38.7329L13.3794 62.8799C13.3788 63.4512 13.5061 64.0155 13.7522 64.533C13.9983 65.0505 14.3573 65.5083 14.8036 65.8741L31.2129 79.3478C31.7623 79.8121 32.4322 80.117 33.1477 80.2284C34.8405 80.4838 37.9755 80.8625 39.2026 80.387C40.6268 79.8233 50.5064 68.7714 53.6593 65.2224C54.2793 64.517 54.6252 63.6184 54.6356 62.6862L54.8775 38.5039"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M34.9609 37.168V53.6173"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M28.8926 55.4148C28.8926 55.4148 33.3976 59.5624 35.0644 59.5624C36.7313 59.5624 41.2363 53.6406 41.2363 53.6406"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M12.8839 45.8533C12.8839 45.8533 -9.95303 24.0245 7.56886 14.315C21.2728 6.73391 25.1528 3.84921 27.4294 2.46842C28.3895 1.87461 29.4633 1.48528 30.5829 1.32509C34.7818 0.73584 47.2278 -0.0908728 54.4297 9.24044C63.1907 20.6209 66.734 29.319 66.5657 32.6699C66.4063 35.836 63.9082 45.053 54.4297 47.0582"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M16.5527 12.0447C16.5527 12.0447 27.3344 17.432 34.9647 16.7297C42.595 16.0274 52.9341 10.8711 52.9341 10.8711"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M9.4043 13.5015C9.4043 13.5015 10.1391 5.3863 16.0621 2.3741C21.9851 -0.638099 28.2446 2.24121 28.2446 2.24121"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M30.1914 68.1133C30.1914 68.1133 33.9701 70.8801 39.2867 68.39"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M24.9964 60.2188L21.748 64.8246"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M28.2374 62.1914L25.6387 64.8233"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M31.4899 62.1914L30.1914 64.8233"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M44.4824 60.2188L48.3804 64.1666"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M41.8809 61.5352L43.8299 64.1671"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M37.9824 62.1914L39.9314 64.8233"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M23.0451 72.7188V83.0034C23.0394 83.5769 23.1492 84.1451 23.367 84.6702C23.5849 85.1954 23.9058 85.6654 24.3086 86.049C25.7599 87.4227 28.5771 89.0946 33.3321 88.3084C40.9129 87.0431 42.5349 81.6568 42.5349 81.3134V77.6093"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M23.05 77.6799C23.05 77.6799 8.45286 76.2618 6.53863 79.0888C4.6244 81.9158 4.86957 89.8257 4.86957 89.8257"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M42.8542 78.1057C42.8542 78.1057 57.4514 76.6876 59.3657 79.5146C61.2799 82.3415 61.0347 90.2515 61.0347 90.2515"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M46.453 94.5141C47.9027 94.5141 49.0779 93.3182 49.0779 91.843C49.0779 90.3678 47.9027 89.1719 46.453 89.1719C45.0033 89.1719 43.8281 90.3678 43.8281 91.843C43.8281 93.3182 45.0033 94.5141 46.453 94.5141Z"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
    />
    <path
      d="M44.1535 48.3736C45.0505 48.3736 45.7776 47.4899 45.7776 46.3997C45.7776 45.3095 45.0505 44.4258 44.1535 44.4258C43.2565 44.4258 42.5293 45.3095 42.5293 46.3997C42.5293 47.4899 43.2565 48.3736 44.1535 48.3736Z"
      fill="#4F60FF"
    />
    <path
      d="M25.8136 48.5182C26.7106 48.5182 27.4378 47.6344 27.4378 46.5442C27.4378 45.4541 26.7106 44.5703 25.8136 44.5703C24.9166 44.5703 24.1895 45.4541 24.1895 46.5442C24.1895 47.6344 24.9166 48.5182 25.8136 48.5182Z"
      fill="#4F60FF"
    />
    <path
      d="M27.5938 10.8711L28.243 20.0827"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M35.3848 6.26562L36.0341 22.057"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M43.1836 9.55469L43.8329 19.4243"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M13.2861 47.7349C12.7893 47.7349 4.16146 42.6127 1.84327 48.7927C-0.474926 54.9727 1.84327 60.6238 7.64747 61.1527C13.4517 61.6817 13.9484 61.5054 13.9484 61.5054"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
    />
    <path
      d="M54.8909 47.7313C55.3876 47.7313 64.0155 42.6171 66.3337 48.7895C68.6519 54.9618 66.3337 60.6236 60.5295 61.1526C54.7253 61.6817 54.2285 61.5054 54.2285 61.5054"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
    />
    <path
      d="M6.1543 33.2428C6.1543 33.2428 16.9723 32.3634 17.7025 30.6048C18.4327 28.8461 16.224 18.6634 15.4217 18.1094"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M62.6784 33.2372C62.6784 33.2372 51.8022 32.396 51.1162 30.7138C50.4302 29.0316 52.6055 19.3 53.4088 18.7617"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M29.0176 33.6367H34.3107"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeLinecap="round"
    />
    <path
      d="M44.0156 33.6367H49.3088"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeLinecap="round"
    />
  </svg>
);

export const AvatarsProjectManager = () => (
  <svg width="52" height="102" viewBox="0 0 52 102" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.65912 38.3398C7.20522 38.9927 8.5676 85.9267 22.2492 85.0445C44.0372 85.0445 38.1357 38.3398 38.1357 38.3398"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M33.3931 31.5765C33.3931 31.5765 34.0921 37.2754 29.181 35.3258C27.9411 34.9036 26.8747 34.1036 26.1403 33.045C25.4058 31.9864 25.0427 30.7257 25.1049 29.4504C25.1084 29.2863 25.049 29.1269 24.9383 29.003C24.8276 28.8791 24.6734 28.7996 24.5058 28.7799L11.4789 27.2008C11.4009 27.1897 11.3214 27.1943 11.2454 27.2145C11.1693 27.2346 11.0984 27.2698 11.037 27.3179C10.9757 27.3659 10.9252 27.4258 10.8887 27.4937C10.8523 27.5616 10.8307 27.6361 10.8253 27.7125C10.6619 29.5827 10.1353 35.3258 9.76313 36.2345C9.30923 37.3196 7.08512 38.881 6.41335 39.2692C5.74158 39.6574 5.29676 29.071 6.63123 27.3419C7.20412 26.6875 7.69757 25.9712 8.10186 25.207"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M38.7715 33.3195C38.7715 33.3195 39.2254 38.251 39.6793 39.3361C40.1332 40.4212 42.6932 41.7974 43.4194 41.4622C44.1457 41.1269 44.0095 26.4208 43.8279 24.6211"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M32.916 34.7397C33.0431 35.816 34.3049 38.9743 36.983 37.8892C39.661 36.8041 38.5444 29.6406 38.5444 29.6406"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M7.30386 26.3136C6.63209 25.4314 -0.957108 7.32879 6.39607 9.06671C13.7492 10.8046 15.5467 14.0599 15.5467 14.0599"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M12.6619 10.8083C12.444 9.72321 16.9013 -0.474973 27.1867 1.4835C37.472 3.44198 37.4539 9.72322 37.4539 9.72322C37.4539 9.72322 41.0215 6.68847 42.1381 8.41757C43.2547 10.1467 42.5829 12.5463 41.6933 13.1903"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M42.8006 12.1014C42.8006 12.1014 48.1566 9.2872 49.945 10.8046C51.7333 12.3219 49.5002 14.9244 48.3836 15.5772C47.267 16.2301 44.8068 17.5269 44.8068 17.5269C44.8068 17.5269 48.3836 18.612 47.267 20.7822C46.1504 22.9524 42.5736 25.1226 39.6777 25.9871"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M24.0166 54.5273L24.0078 63.7375"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M22.7266 73.3351C22.7266 73.3351 24.2335 74.6408 29.5895 70.4062"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M20.9234 62.6172C20.9234 62.6172 20.5876 68.0427 23.9373 68.0427C27.2871 68.0427 26.9512 62.7495 26.9512 62.7495"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M22.5883 45.426L43.9397 45.3555C45.61 45.3555 48.6239 52.5101 45.3922 56.9563C43.8978 58.9227 41.7885 60.3661 39.3797 61.0705C36.971 61.775 34.3923 61.7027 32.0294 60.8645C28.1349 59.2853 26.5826 52.5189 26.6552 48.2491C26.6577 48.1966 26.6491 48.1442 26.6302 48.0951C26.6112 48.0459 26.5822 48.0011 26.5448 47.9631C26.5075 47.9252 26.4626 47.895 26.4129 47.8744C26.3632 47.8538 26.3098 47.8432 26.2558 47.8433H21.8711"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M22.6438 45.4231L3.80698 45.3438C2.12755 45.3438 -0.886348 52.5072 2.3545 56.9534C3.8488 58.9273 5.96324 60.3751 8.37869 61.0785C10.7941 61.7819 13.3795 61.7027 15.7445 60.8527C19.5391 59.3177 21.1459 52.8512 21.1278 48.5726C21.1254 48.478 21.1423 48.3839 21.1776 48.2957C21.2129 48.2076 21.2659 48.1271 21.3335 48.059C21.401 47.9909 21.4818 47.9365 21.5712 47.8989C21.6605 47.8614 21.7567 47.8415 21.854 47.8404H25.9028"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M17.2789 48.8203V52.7108C17.2789 52.7997 17.2425 52.885 17.1778 52.9478C17.1131 53.0107 17.0254 53.046 16.9339 53.046H14.1016"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M31.3066 49.0078V53.0306C31.3066 53.0868 31.3296 53.1406 31.3705 53.1803C31.4113 53.22 31.4667 53.2423 31.5245 53.2423H34.4839"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M19.1517 85.043L19.0705 94.3157C19.0687 94.3831 19.0801 94.4502 19.104 94.5123C19.1278 94.5744 19.1635 94.6301 19.2086 94.6754C19.8259 95.278 22.3035 97.6344 22.7015 97.3736"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M27.0684 85.043L27.2441 93.9688C27.2438 94.069 27.2217 94.1679 27.1795 94.2582C27.1373 94.3486 27.076 94.4281 27.0001 94.4909C26.1606 95.1937 23.3688 97.4528 22.7051 97.3925"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M18.7064 88.0508C18.7064 88.0508 9.22901 88.6948 8.34844 88.6948C7.46788 88.6948 7.25 97.5961 7.25 99.7575"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M28.1504 88.5703C28.1504 88.5703 37.5915 89.2231 38.463 89.2231C39.3345 89.2231 39.5614 98.1157 39.5614 100.286"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M15.0449 40.0508H20.5559"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeLinecap="round"
    />
    <path
      d="M30.6602 40.0508H36.1711"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeLinecap="round"
    />
  </svg>
);

export const AvatarsUXUIDesigner = () => (
  <svg width="88" height="86" viewBox="0 0 88 86" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M64.6078 11.7227C63.7341 12.604 63.1143 20.7675 55.5647 22.2239C48.015 23.6804 50.905 30.9775 43.9005 32.1426C36.896 33.3078 35.7385 29.2298 29.3239 33.0165C22.9094 36.8032 29.0327 36.2281 23.4844 37.9759"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M63.7344 22.5172C63.7344 22.5172 67.1545 21.9346 69.0512 26.894C70.948 31.8533 67.5577 32.4359 73.9872 32.4359"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M68.4375 12.6045C68.4375 12.6045 71.9323 12.3132 71.641 17.5638C71.3498 22.8145 68.1463 23.6883 74.2696 23.9796"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M63.8321 8.32807C63.3318 7.86499 58 0.933849 43.8043 1.52389C35.5901 1.85999 28.4437 14.6468 24.3888 14.6468C20.334 14.6468 20.0129 12.899 17.9668 16.3945"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M10.0724 31.8555C9.19869 32.438 8.90746 32.1468 6.57013 35.941C4.23281 39.7352 1.3429 48.19 14.1646 48.7725"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M7.44715 34.194C7.24553 33.4097 3.71341 22.3259 14.25 21.9375"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M22.0289 50.7227C20.6698 51.111 5.89167 55.5849 18.9224 63.9426"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M17.1634 62.9922C17.1634 62.9922 12.4962 86.1458 32.0984 76.2122"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M27.8164 78.0859C27.8164 78.0859 35.5975 89.2893 50.9059 78.6685"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M70.6777 48.5547C72.0368 48.9431 85.6127 54.5672 72.567 62.9249"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M74.3432 61.9531C74.3432 61.9531 79.0103 85.1067 59.4082 75.1806"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M61.8358 76.2891C61.8358 76.2891 61.5446 88.202 44.9668 82.1223"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M30.4272 10.0716C30.4272 10.0716 22.0263 5.01515 17.1649 9.87741C12.3036 14.7397 13.0877 20.3787 6.27734 19.9903"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M28.1113 44.5308C28.1113 44.5308 28.602 43.4379 32.0426 43.6901C35.4833 43.9423 37.7729 43.9087 37.7729 43.9087"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M64.123 44.5308C64.123 44.5308 63.6324 43.4379 60.1917 43.6901C56.751 43.9423 54.4614 43.9087 54.4614 43.9087"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M35.1788 42.9324C35.983 42.9324 36.635 42.2803 36.635 41.476C36.635 40.6716 35.983 40.0195 35.1788 40.0195C34.3746 40.0195 33.7227 40.6716 33.7227 41.476C33.7227 42.2803 34.3746 42.9324 35.1788 42.9324Z"
      fill="#4F60FF"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M55.5401 43.5926C56.3444 43.5926 56.9963 42.9405 56.9963 42.1361C56.9963 41.3318 56.3444 40.6797 55.5401 40.6797C54.7359 40.6797 54.084 41.3318 54.084 42.1361C54.084 42.9405 54.7359 43.5926 55.5401 43.5926Z"
      fill="#4F60FF"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M53.627 36.0352L58.8797 36.0299"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M33.3984 35.9961L38.6505 36.0816"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M23.3848 48.3867C23.3848 48.3867 19.8975 59.6648 34.474 58.5146"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M13.2803 41.0395C13.2803 41.0395 10.943 50.3756 18.5075 50.6669C26.0721 50.9582 20.4491 50.3308 20.4491 50.3308L20.2549 40.6212C12.9667 42.9515 10.9206 37.9922 10.0469 37.9922"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M70.5343 36.4777C70.5343 36.4777 77.016 36.4777 77.0011 42.5051C77.0011 47.3524 72.0875 48.3085 70.6463 48.4802C70.5969 48.4868 70.5467 48.4825 70.4992 48.4677C70.4517 48.4529 70.4079 48.4279 70.371 48.3945C70.334 48.3611 70.3048 48.3201 70.2853 48.2743C70.2658 48.2285 70.2565 48.179 70.258 48.1292L70.4447 32.5117"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M74.3164 73.0859C78.8744 76.5216 82.9926 80.5051 86.578 84.9466"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M16.4558 71.6641C11.2489 74.9897 6.33268 78.75 1.75977 82.9048"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M40.679 47.2188C40.679 47.2188 39.9323 52.081 43.8004 50.7217"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M52.8507 47.5547C52.8507 47.5547 53.0449 52.4169 49.1543 51.0576"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M44.1914 50.2221C44.1914 50.2221 44.3781 53.2619 46.6706 53.4412C49.105 53.6354 49.2021 50.125 49.2021 50.125"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M51.7012 50.9648C51.7012 50.9648 57.3167 52.2794 58.7729 55.1997C60.229 58.1201 58.1904 61.9217 58.1904 61.9217"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M41.6433 50.8203C41.6433 50.8203 36.2891 51.2087 34.833 54.129C33.3768 57.0494 35.4154 60.8511 35.4154 60.8511"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M42.5933 57.5244C42.5933 57.5244 41.6897 63.1858 46.5734 63.4547C51.9201 63.746 51.0539 57.375 51.0539 57.375"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M68.0307 48.1211C68.0307 48.1211 70.7788 59.3991 59.3535 58.234"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M51.4135 7.35938C50.6668 8.10627 50.1963 14.8955 43.8788 16.1055C37.5613 17.3154 39.9883 23.4026 34.1711 24.3735C28.3539 25.3445 27.3682 21.9387 22.0215 25.1204C16.6748 28.3022 21.7751 27.7943 17.1602 29.2582"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);

export const AvatarsDevops = () => (
  <svg width="74" height="99" viewBox="0 0 74 99" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M51.435 89.5293C47.2177 87.7182 45.2788 82.8362 47.1044 78.6251C48.93 74.414 53.8288 72.4684 58.0462 74.2795L65.6853 77.56C69.9026 79.3711 71.8415 84.2531 70.0159 88.4642C68.1903 92.6753 63.2915 94.6209 59.0741 92.8098L51.435 89.5293Z"
      fill="#FF99FF"
    />
    <path
      d="M13.344 32.8186C13.4413 30.9212 13.995 29.0753 14.9581 27.438C15.9212 25.8006 17.2652 24.4202 18.8758 23.4141C22.6215 21.0803 28.1793 18.6685 34.6648 19.215C46.6735 20.2301 51.3036 21.384 53.9134 31.2309C56.5232 41.0778 58.1012 66.4109 52.4654 69.1611C46.8296 71.9113 39.5897 71.33 34.6648 71.6163C29.74 71.9026 17.3238 73.794 14.7226 67.2785C12.6677 62.2379 12.8151 42.6308 13.344 32.8186Z"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
    />
    <path
      d="M13.7691 14.2955C12.9975 13.5233 12.2258 5.8974 18.8761 5.12526C25.2489 4.38782 26.4975 6.95583 26.4975 6.95583"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M27.9434 4.44953C27.9434 4.44953 33.1457 0.250474 37.689 1.11805C39.2636 1.46996 40.7319 2.19163 41.9727 3.22347C43.2134 4.2553 44.1911 5.56769 44.8249 7.05225"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M47.2422 5.31641C47.2422 5.31641 55.774 5.6027 53.1728 13.862"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M57.502 16.4321C58.0785 16.324 58.6745 16.4102 59.1969 16.677C59.7193 16.9439 60.1386 17.3764 60.3892 17.907C60.6211 18.5026 60.6902 19.1492 60.5897 19.7805C60.4891 20.4117 60.2224 21.0048 59.817 21.4987"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M58.2344 30.3101C58.2344 29.9978 58.2864 25.7987 60.9829 25.417C63.6795 25.0353 64.5032 26.354 64.5032 26.354"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M59.5312 40.6413C59.5312 40.6413 64.0226 41.7431 65.6006 38.7066C67.1786 35.6701 65.8954 31.5144 64.3 30.8984"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M6.61729 29.0528C6.61729 29.0528 2.28201 28.4108 1.90918 25.0012C1.53634 21.5916 3.40051 18.9282 5.47277 18.5898"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M8.13569 13.3203C8.13569 13.3203 10.7368 15.8189 10.5287 18.6732C10.3207 21.5275 7.06055 22.3344 7.06055 22.3344"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M11.1157 40.9321H4.50875C4.50875 40.9321 -0.121293 38.0864 2.72263 34.2257C5.56655 30.365 9.42492 31.5189 9.91047 33.4536C10.396 35.3883 10.5868 37.5312 9.1648 38.0084C7.95961 38.4248 5.47117 37.9389 5.32378 36.2732C5.17638 34.6075 5.32378 34.6855 5.32378 34.6855"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M17.4375 44.793C17.4375 44.793 19.9433 48.0724 23.2207 46.9185C26.4981 45.7646 27.1311 44.8363 27.1311 44.8363"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M52.5906 45.8671C52.5906 45.8671 50.0848 49.1552 46.8073 47.9926C43.5299 46.8301 42.7148 45.7109 42.7148 45.7109"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M27.1383 44.9961L27.0957 48.6659"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M42.7711 45.875L42.7285 49.5448"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M30.4219 53.1992C30.4219 53.1992 30.682 56.496 32.9277 55.4636"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M32.9414 54.9943C32.9414 54.9943 34.8749 57.25 36.4876 54.9336"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M39.5325 53.1641C39.5325 53.1641 38.6048 56.4522 36.3418 55.4024"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M22.0645 37.5234L28.2321 37.581"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M42.6582 37.3125L48.8259 37.3701"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M30.1641 61.593C30.27 62.1417 30.485 62.6635 30.7963 63.1274C31.1076 63.5914 31.5088 63.9881 31.9762 64.2939C32.4436 64.5998 32.9677 64.8086 33.5173 64.908C34.0669 65.0073 34.6308 64.9952 35.1756 64.8724C36.1637 64.6669 37.0788 64.2005 37.8259 63.5216C38.573 62.8427 39.1249 61.9761 39.4242 61.0117"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M33.2754 64.2846C33.2754 64.2846 33.4661 61.3869 34.628 61.196C35.7899 61.0051 36.3622 64.0937 36.3622 64.0937"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M36.1834 64.457C36.1834 64.457 35.9927 67.3547 34.8395 67.5456C33.6864 67.7365 33.1055 64.6566 33.1055 64.6566"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M12.7509 51.2848C11.7884 51.2848 10.295 50.5485 7.59849 52.0928C4.90195 53.6371 4.51177 59.2676 6.41062 61.1936C8.30948 63.1196 13.6228 62.753 13.6228 62.753"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M56.9436 62.0107C57.906 62.0107 59.8308 62.592 62.536 61.0477C65.2412 59.5034 65.6227 53.9076 63.6892 51.9729C61.7557 50.0382 56.1719 50.0469 56.1719 50.0469"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M8.89608 62.9768C8.33263 63.4392 6.59915 67.6227 6.23326 68.5254C6.22595 68.5449 6.22313 68.5658 6.22499 68.5866C6.22685 68.6073 6.23336 68.6274 6.24402 68.6453C6.25469 68.6632 6.26924 68.6785 6.2866 68.6901C6.30396 68.7016 6.32369 68.7091 6.34431 68.712L12.1312 68.9901C12.1874 68.9943 12.2437 68.9826 12.2937 68.9565C12.3436 68.9304 12.3853 68.8909 12.414 68.8423C12.4427 68.7938 12.4573 68.7381 12.4561 68.6817C12.4549 68.6252 12.4381 68.5702 12.4074 68.5227L8.89608 62.9768Z"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M60.1473 62.7539C59.4861 63.1175 57.2301 66.6052 56.8023 67.3621C56.7909 67.3784 56.785 67.3966 56.7852 67.415C56.7853 67.4335 56.7914 67.4516 56.803 67.4678C56.8145 67.484 56.8312 67.4977 56.8514 67.5078C56.8717 67.5178 56.8949 67.5239 56.919 67.5254L63.3757 68.0448C63.4385 68.0512 63.5024 68.044 63.5601 68.0241C63.6179 68.0042 63.6672 67.9725 63.7025 67.9324C63.7379 67.8923 63.7579 67.8454 63.7603 67.7971C63.7627 67.7488 63.7474 67.701 63.7161 67.659L60.1473 62.7539Z"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M29.2918 72.1953L29.3872 79.8213L15.1849 79.8646C14.1165 79.8576 13.0683 80.1559 12.1636 80.7246C11.2589 81.2932 10.5353 82.1086 10.078 83.0747C9.66864 83.9761 9.46149 84.9563 9.47102 85.9463C9.47102 90.2842 9.60974 95.9408 9.60974 95.9408"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M39.6833 71.5469L39.5879 79.1728L53.7902 79.2162C54.8585 79.2091 55.9067 79.5075 56.8114 80.0761C57.7161 80.6448 58.4397 81.4601 58.8971 82.4262C59.3064 83.3277 59.5136 84.3078 59.5041 85.2979C59.5041 89.6358 59.3567 95.2923 59.3567 95.2923"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M29.8848 79.7617C29.8848 79.7617 39.2836 82.0781 43.4801 94.094"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M47.235 79.4414C47.235 79.4414 40.576 82.9117 39.709 86.7117"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M9.4707 87.5685L17.4476 87.4297C17.6139 87.4297 17.7736 87.4952 17.8921 87.6121C18.0105 87.729 18.0782 87.8879 18.0805 88.0543V96.73"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M58.6254 88.881L50.6486 88.7422C50.4822 88.7422 50.3225 88.8077 50.204 88.9246C50.0856 89.0415 50.0179 89.2004 50.0156 89.3668V98.0425"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M26.1481 87.6791C26.2783 87.3095 26.3599 86.9246 26.3909 86.5339C26.5296 85.6663 29.573 83.2979 30.3533 86.5339C31.2203 90.2037 26.4689 92.4334 26.4689 92.4334C26.4689 92.4334 22.4892 92.8325 21.7868 87.0198C21.7528 86.7673 21.7951 86.5105 21.9082 86.2823C22.083 85.9481 22.3778 85.6924 22.7333 85.5669C23.0888 85.4413 23.4787 85.4552 23.8244 85.6056C25.1857 85.9873 25.8793 87.4969 26.1481 87.6791Z"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M39.9902 43.3477V50.4052"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);

export const AvatarsSolutionArchitect = () => (
  <svg width="74" height="105" viewBox="0 0 74 105" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      width="16.6211"
      height="24.9348"
      rx="8.31057"
      transform="matrix(0.397755 -0.917491 0.918857 0.394592 43.5566 19.3242)"
      fill="#C9CEFA"
    />
    <path
      d="M42.0195 39.3674C42.0195 39.3674 45.4843 38.2128 47.5127 38.6494"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M50.9841 5.25799C50.9244 5.25799 53.5784 23.0737 43.9467 33.1185C33.0627 44.4725 15.4768 54.4484 13.464 58.7434C11.5183 62.9083 12.0849 76.5974 12.1221 77.4549C12.1221 77.4549 12.1222 77.4932 12.0849 77.4932L4.39896 77.3248C4.35859 77.3239 4.31968 77.3091 4.28855 77.2827C4.25742 77.2563 4.23591 77.2199 4.2275 77.1793C3.63857 73.7723 -7.01435 10.4029 21.5078 3.68083C50.7903 -3.16371 50.9841 5.25799 50.9841 5.25799Z"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
    />
    <path
      d="M48.8349 2.60156C49.3642 2.84656 58.1609 9.36955 58.9958 16.5969C59.8307 23.8242 61.4857 73.1754 61.0012 74.898C60.6135 76.3067 42.4237 77.1336 40.6718 77.1336C40.6459 77.1321 40.6213 77.1213 40.6022 77.1031C40.5832 77.085 40.5709 77.0605 40.5675 77.0341L40.5078 75.5029"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
    />
    <path
      d="M12.0605 77.48C13.4621 77.0665 13.5515 77.1431 28.8264 77.6484C28.8766 77.6484 28.9248 77.6285 28.9609 77.5928C28.997 77.5571 29.0183 77.5085 29.0202 77.457V75.9258"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
    />
    <path
      d="M47.2559 29.6641C47.2559 29.6641 52.1164 32.7265 51.11 40.329"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M14.6191 6.78733C14.6191 6.78733 29.3573 1.2826 35.0454 5.40923"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M7.28516 55.4531C7.28516 55.4531 7.45664 59.2352 7.62066 61.4708"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M30.595 44.6536C30.595 44.6536 52.2362 42.607 53.906 42.7755C55.5759 42.9439 56.7983 44.547 56.9847 45.7414C57.2083 47.1501 57.9687 56.2685 51.7663 60.0659C46.9058 63.0365 41.091 61.8957 38.4297 56.6666C36.8906 53.5328 36.3433 49.9835 36.8641 46.5146C36.8641 46.285 34.9408 46.4304 34.7171 46.4611L32.4807 46.729C32.3167 46.729 32.308 48.0205 32.3155 48.1966C32.3677 49.3986 32.026 53.3745 31.1612 55.7479C30.6485 57.2948 29.7183 58.6602 28.4789 59.685C27.2396 60.7098 25.7421 61.3517 24.1611 61.5359C20.143 62.2249 16.2143 60.487 15.7148 56.0159"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
    />
    <path
      d="M16.6211 55.696C18.8914 63.1515 26.4372 60.8182 29.2344 57.3599C32.0316 53.9016 30.8422 47.5094 30.5548 46.2456C30.537 46.1849 30.4976 46.1332 30.4445 46.1005C30.3913 46.0679 30.3282 46.0568 30.2674 46.0692L29.2344 45.5508"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
    />
    <path
      d="M35.376 55.4531L35.6213 62.5824C35.6231 62.64 35.6125 62.6973 35.5903 62.7497C35.5681 62.802 35.5349 62.848 35.4935 62.8838C35.452 62.9197 35.4034 62.9445 35.3516 62.9562C35.2997 62.9679 35.2461 62.9662 35.1949 62.9513L31.9648 62.0291"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M29.2227 65.5634C29.3196 65.9003 30.5645 69.5982 35.2536 68.6258C39.9427 67.6535 40.0247 64.8438 40.0247 64.8438"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M13.1969 59.3672C13.1969 59.3672 8.58986 77.0528 28.5166 75.8508C28.5166 75.8508 38.7893 75.5904 42.2483 75.3072C45.7074 75.0239 53.9075 76.5781 52.9933 59.3687"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
    />
    <path
      d="M25.614 52.8368C26.1451 52.8368 26.5757 52.1615 26.5757 51.3286C26.5757 50.4956 26.1451 49.8203 25.614 49.8203C25.0829 49.8203 24.6523 50.4956 24.6523 51.3286C24.6523 52.1615 25.0829 52.8368 25.614 52.8368Z"
      fill="#4F60FF"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
    />
    <path
      d="M43.8972 51.8993C44.4284 51.8993 44.8589 51.224 44.8589 50.3911C44.8589 49.5581 44.4284 48.8828 43.8972 48.8828C43.3661 48.8828 42.9355 49.5581 42.9355 50.3911C42.9355 51.224 43.3661 51.8993 43.8972 51.8993Z"
      fill="#4F60FF"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
    />
    <path
      d="M40.1792 76.1055C40.1792 76.1055 40.4266 84.0881 38.8008 87.1274C37.4365 89.6719 32.34 90.2798 30.8626 84.498C30.0236 81.4346 29.4745 79.505 29.2227 76.3387"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
    />
    <path
      d="M15.5663 103.332C15.5663 103.332 14.9177 85.2939 17.8028 85.1791C20.5834 85.0642 28.2795 85.1052 29.3008 84.998C32.3833 84.0109 28.7763 85.0549 28.8008 85.0716C28.8253 85.0884 28.0968 85.1348 28.1053 85.1637C28.4333 86.0365 30.8487 92.1691 35.3067 92.3834"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M55.4542 102.527C55.4542 102.527 55.7524 85.2934 52.8525 85.1785C49.9525 85.0637 43.4967 85.1785 42.6319 85.0713C42.6165 85.071 37.531 83.0329 41.2988 84.4995C41.2856 84.5076 42.5643 85.1032 42.5573 85.1173C42.3113 85.7987 39.8811 92.2374 35.3262 92.3829"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M40.3359 84.7227C40.6607 84.9463 41.0291 85.0944 41.4154 85.1566C41.8017 85.2187 42.1966 85.1935 42.5724 85.0825"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
    />
    <path
      d="M30.1109 84.5586C29.861 84.8369 29.5379 85.0349 29.1807 85.1287C28.8235 85.2224 27.7458 85.2497 27.3965 85.1287"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
    />
    <path
      d="M26.8645 85.1055C26.7154 85.3887 22.168 99.7133 21.4151 102.087C21.4082 102.111 21.4085 102.136 21.4162 102.16C21.4238 102.184 21.4384 102.204 21.4579 102.219C21.4773 102.234 21.5008 102.243 21.5252 102.244C21.5495 102.245 21.5735 102.238 21.594 102.224C23.5621 100.877 35.315 92.4473 35.6207 92.5392"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
    />
    <path
      d="M44.7647 85.4961C44.9138 85.7794 48.464 99.6503 49.3511 102.491C49.3622 102.526 49.3623 102.563 49.3514 102.599C49.3405 102.634 49.3191 102.664 49.2903 102.686C49.2615 102.708 49.2267 102.721 49.1908 102.721C49.1549 102.722 49.1197 102.711 49.0902 102.69C46.7792 101.005 34.9699 92.915 34.709 93.0068"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
    />
    <path
      d="M54.2878 44.2263L38.915 45.5404C38.8147 45.5505 38.7206 45.5989 38.6486 45.6775C38.5766 45.7562 38.5312 45.8601 38.52 45.9718C38.2932 48.0982 38.3158 50.2485 38.5872 52.3684C38.8736 54.5439 39.8563 56.53 41.3537 57.9595C42.8511 59.3889 44.7618 60.1648 46.7323 60.1437C47.9776 60.185 49.2154 59.914 50.3544 59.3508C51.4935 58.7875 52.5048 57.9463 53.3139 56.8892C54.6217 55.2234 55.4436 53.1527 55.6704 50.9527C55.8971 48.7527 55.5179 46.5278 54.5831 44.5743C54.518 44.4281 54.4152 44.3066 54.2878 44.2263ZM54.2878 44.2263C54.1604 44.1461 54.4336 44.213 54.2878 44.2263Z"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
    />
    <path
      d="M30.7988 45.3557C30.817 45.3665 31.5068 45.8439 31.4998 45.6719C31.4956 45.5698 31.4516 45.4765 31.3856 45.3808C31.2849 45.2348 31.1766 45.3538 31.2454 45.4585C31.2909 45.5279 31.6512 45.5709 31.7802 45.5692C32.0982 45.5649 32.4438 45.4332 32.7148 45.3663C33.0635 45.28 33.6273 45.332 33.9973 45.332C34.5081 45.332 32.9754 45.3253 32.4656 45.3096C32.1699 45.3005 31.8731 45.2973 31.5984 45.357C31.3684 45.4071 31.5451 45.6652 31.6504 45.7207C32.0047 45.9074 32.9581 45.9401 33.3431 45.7773C33.6718 45.6383 34.0282 45.5034 33.9765 45.2609C33.9397 45.0879 32.8765 45.0515 32.6083 45.0725C32.5534 45.0768 32.5735 45.1328 32.5746 45.1528C32.5827 45.3049 32.7978 45.378 33.0679 45.4229C33.7184 45.5311 34.4321 45.4616 35.0877 45.4137C35.2651 45.4007 36.4526 45.2502 35.7575 45.1897C35.342 45.1536 34.9175 45.1306 34.4983 45.108C34.3742 45.1014 34.1016 45.0665 33.9765 45.1001C33.6751 45.1811 34.2001 45.415 34.314 45.4453C34.6789 45.5424 35.521 45.5783 35.8899 45.4598C36.0245 45.4165 36.1635 45.3461 36.1729 45.2609C36.1843 45.1565 35.9313 45.1653 35.7731 45.1555C35.4002 45.1322 34.8977 45.1457 34.7813 45.3689C34.6726 45.5774 35.5667 45.5231 35.7886 45.5217C36.2037 45.5192 36.6058 45.3541 36.9361 45.2385C37.4786 45.0486 36.7273 45.0919 36.4558 45.1318C36.1101 45.1825 35.8457 45.3069 35.8457 45.4967C35.8457 45.724 36.6917 45.7171 36.988 45.7101C37.4343 45.6996 37.8349 45.5482 37.8552 45.3083C37.8765 45.0562 37.6193 45.0237 37.1386 45.0237C36.6068 45.0237 37.2918 45.0237 37.4554 45.0237C37.5542 45.0237 37.7565 45.0424 37.8344 45C37.8344 45 37.4272 45.0354 37.3437 45.0632C36.9521 45.194 36.8165 45.3995 36.6973 45.6113C36.6126 45.7618 37.3815 45.717 37.528 45.7062C37.6616 45.6963 37.7049 45.6929 37.7383 45.6284C37.7959 45.5173 37.3601 45.51 37.2217 45.5033C36.8755 45.4866 36.5206 45.498 36.1729 45.498"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
    />
  </svg>
);

export const AvatarsBusinessAnalyst = () => (
  <svg width="63" height="111" viewBox="0 0 63 111" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M33.807 9.8433C32.4939 9.8433 32.4636 9.8433 31.9551 9.8433C26.1186 9.90003 5.51245 9.13424 4.44229 47.6223C3.27348 89.4571 10.886 101.391 36.2205 101.568C62.185 101.752 64.0596 59.57 60.0523 33.8593C56.0449 8.14863 33.807 9.8433 33.807 9.8433Z"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M25.1016 6.03754C25.1016 6.03754 33.5947 17.03 40.3634 11.1404C45.7028 6.47963 40.7491 0.874749 36.7559 1.00213C33.9123 1.09205 29.5032 4.74871 32.8308 9.34951"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M6.97461 30.9961C6.97461 30.9961 14.5179 38.4302 11.4398 45.1495C8.36178 51.8688 10.9145 84.0561 13.4344 84.7505C15.9543 85.4449 15.2156 79.8829 17.0296 77.364C18.507 75.3217 25.8697 73.2249 27.2651 73.6878"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M59.0236 30.9961C59.0236 30.9961 50.0869 38.5402 53.1614 45.3713C56.236 52.2025 53.6862 84.9257 51.1691 85.6317C48.6521 86.3376 49.39 80.6831 47.578 78.1222C46.1023 76.0459 38.3626 73.8034 36.9688 74.2741"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M16.9961 38.9805L24.5287 39.1678"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M39.8301 38.1836L47.3627 38.3784"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M23.8574 70.4492C24.7436 71.7909 25.9186 72.9214 27.2982 73.7597C28.6777 74.5979 30.2275 75.123 31.8363 75.2973C37.2437 75.8068 39.5579 72.2326 41.615 70.4492"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M26.9102 80.4297C26.9102 80.4297 28.7101 85.0155 36.1747 81.9283"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M43.125 100.328C43.125 100.328 53.1685 100.838 57.2902 100.583C61.412 100.328 61.6691 107.724 61.6691 109.515"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M20.2182 100.07C20.2182 100.07 10.1747 100.58 6.05298 100.325C1.93121 100.07 1.68164 107.466 1.68164 109.257"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M23.371 100.266C22.0777 100.775 22.713 109.475 32.7035 109.063C41.9075 108.68 41.5823 101.09 41.5823 101.09"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M50.0756 52.2908C50.0756 52.2908 49.4856 51.3167 45.3488 51.5415C41.2119 51.7663 38.459 51.7363 38.459 51.7363"
      stroke="#4F60FF"
      strokeWidth="0.882195"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M40.9396 50.7856C41.7541 50.7856 42.4144 50.1314 42.4144 49.3245C42.4144 48.5175 41.7541 47.8633 40.9396 47.8633C40.1251 47.8633 39.4648 48.5175 39.4648 49.3245C39.4648 50.1314 40.1251 50.7856 40.9396 50.7856Z"
      fill="#4F60FF"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M14.1699 52.4705C14.1699 52.4705 14.7598 51.4964 18.8967 51.7212C23.0336 51.946 25.7865 51.916 25.7865 51.916"
      stroke="#4F60FF"
      strokeWidth="0.882195"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M23.3029 50.9692C24.1174 50.9692 24.7776 50.315 24.7776 49.508C24.7776 48.7011 24.1174 48.0469 23.3029 48.0469C22.4884 48.0469 21.8281 48.7011 21.8281 49.508C21.8281 50.315 22.4884 50.9692 23.3029 50.9692Z"
      fill="#4F60FF"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);

export const AvatarsFrontendDev = () => (
  <svg width="85" height="100" viewBox="0 0 85 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M40.3667 3.38281C39.6296 3.38281 60.438 4.07569 68.723 16.8717"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M32.8023 4.89453C32.0652 4.89453 12.7311 6.89944 12.4805 31.9903C12.4805 31.9903 81.7679 34.2458 82.505 34.2458C83.2421 34.2458 85.763 18.4424 70.7114 16.9387C55.6598 15.4351 26.5591 32.2115 26.5591 32.2115"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M44.5977 27.471C46.633 26.3054 48.8357 25.4601 51.1284 24.9648"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M33.382 5.89844C32.3796 5.89844 20.0332 16.1736 20.5344 29.2277"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M19.5349 32.6797C18.8346 32.7608 19.5349 69.3652 20.5373 72.6747C21.5398 75.9843 40.2695 83.1784 43.0262 83.4143"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M43.1191 83.3958C43.3992 83.3958 45.5663 83.3074 45.5663 83.3074C45.5663 83.3074 60.9054 74.3516 61.4729 73.7251C62.0405 73.0985 65.7702 34.1649 62.9103 33.7227"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M12.4966 32.0508C11.2583 32.552 4.85289 41.8321 10.0126 46.6011C15.1723 51.3702 9.39343 63.1638 9.39343 65.4193C9.39343 67.6748 10.8676 72.1933 10.0126 73.4537"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M7.48836 49.3047C5.35022 55.2565 4.91623 61.6871 6.23528 67.8722C8.47607 77.9115 9.21317 80.1671 7.73897 82.1794C6.26477 84.1916 7.23774 83.1818 7.23774 83.1818"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M16.0232 76.6562C15.0134 77.3934 9.24185 78.4106 12.7578 81.9265C16.2738 85.4425 15.5219 91.9585 13.4949 93.2115"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M68.4609 34.1836C71.9769 37.191 75.7435 39.7045 71.2251 43.9723C66.7066 48.2401 72.4781 54.0042 71.9622 58.0214C71.4462 62.0386 67.4437 75.0853 75.2275 76.0877"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M66.7916 78.0938C65.7081 80.2535 67.5287 82.0446 71.0963 86.7178C74.6639 91.391 70.0201 97.4869 68.229 98.9242"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M34.0918 80.582C34.0918 80.582 32.8313 93.2012 37.0991 95.0955C41.3669 96.9899 43.3719 94.4616 44.3743 91.6238C45.3768 88.786 45.5684 83.7368 45.5684 83.7368"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M75.3041 66.5898C74.567 71.4326 79.8815 75.435 75.1641 79.5702"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M45.1311 44.7812C45.1311 44.7812 44.8731 64.108 43.6569 64.108C42.4407 64.108 39.3965 61.5945 39.3965 61.5945"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M45.4062 71.5628C45.4062 71.5628 39.1262 76.0739 33.6126 68.0469"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M15.5038 65.2231C16.6396 65.2231 17.5603 63.4147 17.5603 61.1838C17.5603 58.953 16.6396 57.1445 15.5038 57.1445C14.368 57.1445 13.4473 58.953 13.4473 61.1838C13.4473 63.4147 14.368 65.2231 15.5038 65.2231Z"
      stroke="#4F60FF"
      strokeWidth="0.882195"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M66.5409 65.5513C67.6767 65.5513 68.5974 63.7428 68.5974 61.512C68.5974 59.2811 67.6767 57.4727 66.5409 57.4727C65.4051 57.4727 64.4844 59.2811 64.4844 61.512C64.4844 63.7428 65.4051 65.5513 66.5409 65.5513Z"
      stroke="#4F60FF"
      strokeWidth="0.882195"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M32.832 52.6989C33.3002 52.6989 33.6797 51.8507 33.6797 50.8045C33.6797 49.7583 33.3002 48.9102 32.832 48.9102C32.3639 48.9102 31.9844 49.7583 31.9844 50.8045C31.9844 51.8507 32.3639 52.6989 32.832 52.6989Z"
      fill="#4F60FF"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
    />
    <path
      d="M50.9063 53.7692C51.3744 53.7692 51.7539 52.921 51.7539 51.8748C51.7539 50.8286 51.3744 49.9805 50.9063 49.9805C50.4381 49.9805 50.0586 50.8286 50.0586 51.8748C50.0586 52.921 50.4381 53.7692 50.9063 53.7692Z"
      fill="#4F60FF"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
    />
    <path
      d="M44.1758 7.67969C44.5959 7.67969 49.5345 11.7853 49.3355 17.262"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M19.897 79.9414C19.897 79.9414 18.0395 82.0348 19.4031 83.3984C20.7668 84.762 18.666 86.9807 18.666 86.9807"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M59.9629 81.1367C59.9629 81.1367 62.786 84.3357 60.7 86.407C58.614 88.4782 61.8351 91.8689 61.8351 91.8689"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M7.18036 77.5352C6.61279 77.5352 -0.190644 79.9823 2.47029 83.1813C5.13122 86.3804 7.9322 94.4737 3.03786 96.1617"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M45.834 88.8008C46.6743 89.2652 55.0772 87.9163 57.3401 90.1497C59.603 92.3831 59.9789 98.3978 59.9789 98.3978"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M33.2582 88.5547C32.4179 89.0191 24.015 87.6702 21.7521 89.9036C19.4892 92.137 19.1133 98.1517 19.1133 98.1517"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M51.0371 43.8242L56.2853 43.9864"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M28.4531 42.8203L33.7013 42.9825"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M76.2942 34.8672C78.4379 40.8177 78.8745 47.2491 77.5547 53.4347C75.2918 63.4667 74.5399 65.7296 76.0805 67.7345C77.621 69.7394 79.0289 69.6214 79.0289 69.6214"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M35.7784 6.5135C37.8219 6.5135 39.4786 5.27926 39.4786 3.75675C39.4786 2.23423 37.8219 1 35.7784 1C33.7348 1 32.0781 2.23423 32.0781 3.75675C32.0781 5.27926 33.7348 6.5135 35.7784 6.5135Z"
      fill="#4F60FF"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M82.5509 27.0508C82.5509 27.0508 79.5435 31.3112 71.7598 30.3088"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M63.4297 21.5168C64.8642 21.0921 66.3567 20.8956 67.8523 20.9345"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M18.7159 57.4337C18.1961 57.5705 17.6492 57.5668 17.1312 57.4231C16.6132 57.2793 16.1426 57.0007 15.7675 56.6155C14.3376 55.3035 14.1312 50.5418 15.1557 48.9054C16.1803 47.2691 19.1435 47.2617 19.1435 47.2617"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M63.8125 57.1068C64.4128 57.2204 65.0311 57.1951 65.6201 57.0329C66.2091 56.8706 66.7531 56.5757 67.2105 56.1706C68.6405 54.8586 68.8395 50.1043 67.8223 48.4606C66.8051 46.8168 63.8346 46.8242 63.8346 46.8242"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M49.501 89.4531C49.4315 91.9688 48.3833 94.3582 46.5794 96.113C44.7754 97.8678 42.358 98.8496 39.8413 98.8496C37.3247 98.8496 34.9073 97.8678 33.1033 96.113C31.2993 94.3582 30.2511 91.9688 30.1816 89.4531"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M12.6211 32.5117C12.6211 32.5117 14.0953 44.1874 18.739 46.7009"
      stroke="#4F60FF"
      strokeWidth="1.76439"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);
