import styled from 'styled-components';

export const AvatarListItems = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.newTheme.spacing(4)};
  justify-content: flex-start;
  margin-top: 0;

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    margin-top: ${({ theme }) => theme.newTheme.spacing(10)};
  }
`;

export const AvatarItem = styled.li`
  width: 20%;
  background-color: ${({ theme }) => theme.newTheme.color.white};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.newTheme.border.radius.main};
  overflow: hidden;
  text-align: center;
  padding: ${({ theme }) => `${theme.newTheme.spacing(5)} 0`};

  @media only screen and (${({ theme }) => theme.breakpoints.tabletLandscape}) {
    width: 28%;
    height: ${({ theme }) => theme.newTheme.spacing(33)};
    padding: ${({ theme }) => `${theme.newTheme.spacing(23)} ${theme.newTheme.spacing(1)}`};
  }

  @media only screen and (${({ theme }) => theme.breakpoints.tablet}) {
    width: 28%;
    height: ${({ theme }) => theme.newTheme.spacing(20)};
  }
  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    width: 47%;
    height: ${({ theme }) => theme.newTheme.spacing(40)};
  }
`;

export const AvatarContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media only screen and (${({ theme }) => theme.breakpoints.tabletLandscape}) {
    max-height: ${({ theme }) => theme.newTheme.spacing(22)};
  }
`;

export const AvatarImage = styled.div`
  @media only screen and (${({ theme }) => theme.breakpoints.tabletLandscape}) {
    svg {
      height: ${({ theme }) => theme.newTheme.spacing(20)};
    }
  }
`;

export const AvatarTitle = styled.h6`
  margin: ${({ theme }) => `${theme.newTheme.spacing(1)} 0`};
  font-size: ${({ theme }) => theme.newTheme.font.text3.size};
  line-height: ${({ theme }) => theme.newTheme.font.text4.lineHeight};
  font-weight: ${({ theme }) => theme.newTheme.font.weight.regular};
  color: ${({ theme }) => theme.newTheme.color.primary.main};
  padding-top: ${({ theme }) => theme.newTheme.spacing(1)};

  span {
    display: block;
    font-size: ${({ theme }) => theme.newTheme.font.text3.size};
    line-height: ${({ theme }) => theme.newTheme.font.text4.lineHeight};
    font-weight: ${({ theme }) => theme.newTheme.font.weight.regular};
    color: ${({ theme }) => theme.newTheme.color.primary.main};
  }
`;

export const ColAvatars = styled.div`
  padding: 0 !important;
  margin: 0;
`;
