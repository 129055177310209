import React from 'react';

import avatarsList from 'domain/avatars-list';
import { AvatarContent, AvatarImage, AvatarItem, AvatarTitle, AvatarListItems } from './styles';

const AvatarsList = () => {
  return (
    <AvatarListItems>
      {avatarsList.map(avatar => (
        <AvatarItem key={avatar.id} backgroundColor={avatar.type}>
          <AvatarContent>
            <AvatarImage>{avatar.image}</AvatarImage>
            <AvatarTitle>{avatar.name}</AvatarTitle>
          </AvatarContent>
        </AvatarItem>
      ))}
    </AvatarListItems>
  );
};

export default AvatarsList;
