import React from 'react';

import { useStaticQuery, graphql } from 'gatsby';
import { Container, Row, Col } from 'react-grid-system';
import Image from 'core/image';
import Layout from 'core/layout';
import MetaTags from 'core/meta-tags/index';
import PageSection from 'atomic-design/atoms/page-section';
import theme from 'styles/theme';
import ServicesChecklist from 'atomic-design/organisms/services-checklist';
import TestimonialCard from 'atomic-design/molecules/testimonial-card';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import LetUsAboutSection from 'atomic-design/organisms/let-us-about-section';
import BuiltSection from 'atomic-design/organisms/built-section';
import CallToAction from 'atomic-design/molecules/call-to-action';
import useIsMobile from 'hooks/useIsMobile';
import { renderLineBreak } from 'common/string-helpers';
import ButtonGradient from 'atomic-design/atoms/button-gradient';
import MainContainer from 'atomic-design/atoms/main-container';
import ImgAndInformation from 'atomic-design/molecules/img-and-information';
import ListCircleImages from 'atomic-design/organisms/list-circle-images';
import homeList from 'domain/home-list';
import Testimonial from 'atomic-design/molecules/testimonial';
import AvatarsList from 'atomic-design/organisms/avatars-list';
import AutoSlider from 'atomic-design/molecules/auto-slider';
import { ArrowsLeft, ArrowRight } from 'ui/svg';
import BadgesContainer from 'atomic-design/molecules/badges-container';

import {
  WeSpeakContent,
  Content,
  MainContent,
  Diagonal,
  HomeContent,
  ContainerGrid,
  ContainerWords,
  ContentText,
  ImgContainer,
  Word,
  WordList,
  ColGrid,
  ClientsContainer,
  ClientTitle,
  PageHeaderContainer,
  PageHeaderDescription,
  PageHeaderSubtitle,
  TestimonialGridItem,
  TestimonialGridList,
  RowGrid,
  TestimonialGridTitle,
  VerticalCentered,
  InfoGeneral,
  InfoTitle,
  ActionContent,
  AvatarsContent,
  AvatarsListContainer,
  ImgContainerAvatars,
  ColAvatars,
  MoreThanDevelopmentContent,
  TextContainer,
  Title,
  Description,
  ColAvatarsDesk,
} from '../styles/pages/home-styles';

const HomePage = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulTestimonialFeatured(filter: { pageId: { eq: "home" } }) {
        edges {
          node {
            title {
              json
            }
            description {
              description
            }
            author {
              image {
                contentful_id
              }
              name
              role
            }
          }
        }
      }
      allContentfulTestimonialWall(sort: { fields: order }) {
        edges {
          node {
            title
            description {
              description
            }
            author {
              image {
                contentful_id
              }
              name
              role
            }
            order
          }
        }
      }
      allContentfulClient(
        filter: { pagesIn: { elemMatch: { url: { eq: "home" } } } }
        sort: { fields: order }
      ) {
        edges {
          node {
            companyName
            website
            logo {
              contentful_id
              title
            }
            pagesIn {
              url
            }
            order
          }
        }
      }
      allContentfulCaseStudy(
        filter: { pageId: { eq: "home" } }
        sort: { fields: publishedDate, order: DESC }
        limit: 2
      ) {
        edges {
          node {
            title
            slug
            subtitle
            LimitedAbstract
            body {
              json
            }
            thumbnail {
              contentful_id
              title
              file {
                url
              }
            }
            technologies {
              name
              url
              image {
                file {
                  url
                }
                contentful_id
                description
              }
            }
            filteredIn {
              optionName
            }
          }
        }
      }
      allContentfulPage(filter: { url: { eq: "home" } }) {
        edges {
          node {
            headerTitle {
              json
            }
            headerSubtitle
            headerDescription {
              headerDescription
            }
            metatagImage {
              file {
                url
              }
            }
            metatagTitle
            metatagDescription
          }
        }
      }
    }
  `);

  const isMobile = useIsMobile('mobile');

  const pageHeaderData = data.allContentfulPage.edges[0].node;
  const clients = data.allContentfulClient.edges;
  const caseStudiesData = data.allContentfulCaseStudy.edges;
  const testimonialFeatured = data.allContentfulTestimonialFeatured.edges[0].node;
  const testimonialWall = data.allContentfulTestimonialWall.edges;
  const metatagImage = data.allContentfulPage.edges[0].node.metatagImage.file.url;
  const metatagDescription = data.allContentfulPage.edges[0].node.metatagDescription;
  const metatagTitle = data.allContentfulPage.edges[0].node.metatagTitle;

  return (
    <Layout isHome path={location.pathname} bodyType="xWide">
      <MetaTags
        title={metatagTitle}
        description={metatagDescription}
        charSet="utf-8"
        image={metatagImage}
        viewport="width=device-width, initial-scale=1"
        type="website"
      />
      <HomeContent>
        <PageSection
          background={theme.newTheme.color.gradient.background.home}
          containerSize="fluid"
        >
          <ImgContainer>
            <Image
              alt="people working in NaNLABS"
              partialPathName={!isMobile ? 'section-home-hero' : 'res-hero-home'}
              width="100%"
              fluid
            />
          </ImgContainer>
          <MainContainer>
            <PageHeaderContainer>
              <PageHeaderSubtitle>
                {documentToReactComponents(pageHeaderData.headerTitle.json)}
              </PageHeaderSubtitle>
              <PageHeaderDescription>
                {renderLineBreak(pageHeaderData.headerDescription.headerDescription)}
              </PageHeaderDescription>
              <ButtonGradient
                to="/contact"
                title="Signal Us Now"
                ariaLabel="link to contact"
                variant="light"
              />
            </PageHeaderContainer>
          </MainContainer>
        </PageSection>
      </HomeContent>

      <PageSection
        padding={
          isMobile ? `0 0 ${theme.newTheme.spacing(10)}` : `0 0 ${theme.newTheme.spacing(28)}`
        }
        containerSize="fluid"
      >
        <BadgesContainer />
      </PageSection>

      <PageSection containerSize="fluid">
        <MainContainer>
          <ClientTitle>
            <span>Powering</span>
            software teams,
            <span>from series A to IPO</span>
          </ClientTitle>
        </MainContainer>
        <ClientsContainer>
          <AutoSlider clients={clients} />
        </ClientsContainer>
      </PageSection>

      <MoreThanDevelopmentContent>
        <PageSection
          background={theme.newTheme.color.gradient.background.home}
          containerSize="fluid"
        >
          {!isMobile && (
            <ImgContainer>
              <Image
                alt="teamwork in NaNLABS"
                partialPathName="more-than-development-hero"
                width="100%"
                fluid
              />
            </ImgContainer>
          )}
          <MainContainer size={!isMobile ? 'sm' : 'fluid'}>
            <TextContainer>
              <Title>
                More Than Code: <span>Care. Craft. Team Up. Triumph!</span>
              </Title>
              <Description>
                You don't just want code from a nearshore development partner. You want to scale
                faster, sign users, and boost your capacity. As your tech sidekicks, when you join
                forces with us, you're inheriting a squad of strategists, experts, and
                troubleshooters who stand ready to amplify your tech prowess and lead your project
                to success.
              </Description>
              <ButtonGradient
                to="/work"
                title="See for yourself"
                ariaLabel="link to work"
                variant="light"
              />
            </TextContainer>
            {isMobile && (
              <Image
                alt="teamwork in NaNLABS"
                partialPathName="more-than-development-mob"
                width="100%"
                fluid
              />
            )}
          </MainContainer>
        </PageSection>
      </MoreThanDevelopmentContent>

      <PageSection background={`${theme.newTheme.color.primary.light}20`}>
        <MainContent>
          <Content>
            <TestimonialCard
              title={documentToReactComponents(testimonialFeatured.title.json)}
              description={testimonialFeatured.description.description}
              image={testimonialFeatured.author.image.contentful_id}
              authorName={testimonialFeatured.author.name}
              authorRol={testimonialFeatured.author.role}
              alt={`${testimonialFeatured.author.name} - ${testimonialFeatured.author.rol}`}
              textAlign="center"
              quoteColor={theme.newTheme.color.primary.light}
              nameColor={theme.newTheme.color.primary.main}
            />
          </Content>
        </MainContent>
        <Diagonal />
      </PageSection>

      <PageSection
        padding={`${theme.newTheme.spacing(25)} 0 ${theme.newTheme.spacing(25)} 0`}
        mobilePadding={`${theme.newTheme.spacing(25)} 0 ${theme.newTheme.spacing(15)} 0`}
      >
        <ServicesChecklist />
      </PageSection>

      <PageSection background={theme.newTheme.color.background.darkBlue} containerSize="fluid">
        <Container component={ContainerGrid}>
          <Row>
            <Col xs={12} sm={12} md={6} lg={6} component={ColGrid}>
              <ContentText>
                <ContainerWords>
                  <ArrowsLeft />
                  <WordList>
                    <Word>Craft</Word>
                    <Word>Launch</Word>
                    <Word>Conquer</Word>
                    <Word>Repeat</Word>
                  </WordList>
                  <ArrowRight />
                </ContainerWords>
              </ContentText>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} component={ColGrid}>
              <ImgContainer>
                <Image
                  alt="craft launch conquer repeat image"
                  partialPathName="craft-launch-hero"
                  width="100%"
                  desktopWidth="370px"
                  tabletLandscapeWidth="1450px"
                  tabletWidth="450px"
                  mobileHeight="200px"
                  fluid
                />
              </ImgContainer>
            </Col>
          </Row>
        </Container>
      </PageSection>

      <PageSection margin={`${theme.newTheme.spacing(25)} 0 ${theme.newTheme.spacing(25)} 0`}>
        <ListCircleImages
          subtitle="The Journey"
          title={homeList.title}
          description={homeList.description}
          buttonText={homeList.buttonText}
          colorList={theme.newTheme.color.primary.main}
          list={homeList.list}
        />
      </PageSection>

      <PageSection
        padding={`${theme.newTheme.spacing(10)} 0 ${theme.newTheme.spacing(25)} 0`}
        mobilePadding={`0 0 ${theme.newTheme.spacing(15)} 0`}
      >
        <WeSpeakContent>
          <ImgAndInformation
            title={['We speak', <span>your language</span>]}
            description="Literally. We speak English. And figuratively. We know our craft. 92% of our team boasts a tech background, making us your ideal tech sidekick. With a decade of cross-industries expertise, you can be sure to talk to someone who truly understands your world."
            image="we-speak-your-language"
            altImage="People working on teams"
            leftImage={false}
          />
        </WeSpeakContent>
      </PageSection>

      <AvatarsContent>
        <PageSection
          background={theme.newTheme.color.gradient.background.home}
          containerSize="fluid"
          padding={isMobile && `${theme.newTheme.spacing(20)} 0 `}
        >
          {!isMobile && (
            <ImgContainerAvatars>
              <Image
                alt="people working in NaNLABS"
                partialPathName="avatars-hero"
                width="100%"
                fluid
              />
            </ImgContainerAvatars>
          )}
          <MainContainer>
            {isMobile && (
              <InfoTitle>
                Every role you
                <span>need to scale</span>
                and grow
              </InfoTitle>
            )}
            <Container component={ContainerGrid}>
              <AvatarsListContainer>
                <Row>
                  <Col xs={12} sm={12} md={8} lg={8} component={ColAvatarsDesk}>
                    <AvatarsList />
                  </Col>
                  <Col xs={12} sm={12} md={4} lg={4} component={ColAvatars}>
                    <VerticalCentered>
                      <InfoGeneral>
                        {!isMobile && (
                          <InfoTitle>
                            Every role you need
                            <span> to scale and grow</span>
                          </InfoTitle>
                        )}
                        <ActionContent>
                          <CallToAction
                            sectionTitle="Get to know us better?"
                            buttonTitle="Meet the team"
                            to="/about-us"
                          />
                        </ActionContent>
                      </InfoGeneral>
                    </VerticalCentered>
                  </Col>
                </Row>
              </AvatarsListContainer>
            </Container>
          </MainContainer>
        </PageSection>
      </AvatarsContent>

      <PageSection
        background={`${theme.newTheme.color.primary.light}20`}
        containerSize="sm"
        padding={`${theme.newTheme.spacing(20)} 0`}
      >
        <Container component={ContainerGrid}>
          <TestimonialGridTitle>
            Our clients
            <span>say it best!</span>
          </TestimonialGridTitle>
          <TestimonialGridList>
            <Row gutterWidth={80}>
              {testimonialWall.map(testimonial => (
                <Col xs={12} sm={6} md={6} lg={4} component={RowGrid}>
                  <TestimonialGridItem key={testimonial.node.author.name}>
                    <Testimonial
                      description={testimonial.node.description.description}
                      image={testimonial.node.author.image.contentful_id}
                      authorName={testimonial.node.author.name}
                      authorRol={testimonial.node.author.role}
                      alt={`${testimonial.node.author.name} - ${testimonial.node.author.rol}`}
                    />
                  </TestimonialGridItem>
                </Col>
              ))}
            </Row>
          </TestimonialGridList>
        </Container>
      </PageSection>

      <PageSection padding={`${theme.newTheme.spacing(25)} 0`}>
        <BuiltSection
          caseStudies={caseStudiesData}
          subtitle="With top-tier software solutions that achieve goals."
          titleColor={theme.newTheme.color.primary.main}
        />
        <CallToAction
          sectionTitle="There's more where that came from."
          buttonTitle="Take a look at our work "
          to="/work"
        />
      </PageSection>

      <LetUsAboutSection
        title="Think we might be a fit? Let's build and grow together."
        showJoinTeamButton={false}
      />
    </Layout>
  );
};

export default HomePage;
