import React from 'react';
import PropTypes from 'prop-types';

import { Col } from 'react-grid-system';
import CheckList from 'atomic-design/molecules/check-list';
import Button from 'atomic-design/atoms/button';
import Link from 'atomic-design/atoms/link';
import {
  Icon,
  TitleCheckList,
  TextCheckList,
  ContainerCheckListItem,
  ContainerInfo,
  ContainerCheckListButton,
} from './styles';

const IconTitleCheckList = ({
  key,
  image,
  alt,
  title,
  description,
  list,
  isPositive,
  to,
  buttonText,
  buttonVariant,
  buttonColor,
}) => {
  return (
    <Col xs={12} sm={12} md={6} lg={4} key={key}>
      <ContainerCheckListItem>
        <ContainerInfo>
          <Icon src={image} alt={alt} />
          <TitleCheckList>{title}</TitleCheckList>
          <TextCheckList>{description}</TextCheckList>
        </ContainerInfo>
        <ContainerCheckListButton>
          <CheckList pointsList={list} isPositive={isPositive} />
          <Link to={to}>
            <Button variant={buttonVariant} color={buttonColor}>
              {buttonText}
            </Button>
          </Link>
        </ContainerCheckListButton>
      </ContainerCheckListItem>
    </Col>
  );
};

IconTitleCheckList.propTypes = {
  image: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  alt: PropTypes.string,
  key: PropTypes.string,
  list: PropTypes.array.isRequired,
  isPositive: PropTypes.bool,
  to: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonVariant: PropTypes.string.isRequired,
  buttonColor: PropTypes.string.isRequired,
};

IconTitleCheckList.defaultProps = {
  buttonText: 'Find out more',
  buttonVariant: 'outlined',
  buttonColor: 'secondary',
};

export default IconTitleCheckList;
