import React from 'react';

import hands from 'static/images/icons/team-aug-home.svg';
import red from 'static/images/icons/consultancy-home.svg';
import cube from 'static/images/icons/mvp-home.svg';

const checklistHome = [
  {
    key: 1,
    image: cube,
    alt: 'software development outsourcing',
    title: 'Software Development Outsourcing',
    description:
      'Our E2E custom software development service ensures technical excellence for optimal product performance. Agility, security, and scalability are at the core of our approach.',
    isPositive: true,
    list: [
      <p>Technical & Product Strategic Planning</p>,
      <p>Agile Software Development </p>,
      <p>Production Deployment</p>,
      <p>Ongoing Support and Maintenance </p>,
    ],
    to: '/services/software-development-outsourcing-services',
  },
  {
    key: 2,
    image: hands,
    alt: 'team augmentation',
    title: 'Team Augmentation',
    description:
      'Let’s power your team up and unclog the bottleneck! Get your tailored sidekick squad: dedicated experts who seamlessly blend with your in-house team to smash your goals.',
    isPositive: true,
    list: [
      <p>Efficiency & Agility</p>,
      <p>Collaboration & Open Communication</p>,
      <p>Quality & Security Assurance</p>,
      <p>Epic wins for your project!</p>,
    ],
    to: '/services/team-augmentation',
  },
  {
    key: 3,
    image: red,
    alt: 'tech consultancy',
    title: 'Tech Consultancy',
    description:
      'Let’s solve complex technical issues and dodge development roadblocks together! Combine your vision with our cross-industry experience and tech know-how to optimize your business.',
    isPositive: true,
    list: [
      <p>Software Architecture Consultancy</p>,
      <p>Data Engineering Consultancy</p>,
      <p>Research & Development Consultancy</p>,
      <p>Rapid Prototyping & more</p>,
    ],
    to: '/services/tech-consultancy',
  },
];

export default checklistHome;
